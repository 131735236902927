import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TAddressMdl, TLocation, TPropertyMdl } from "properties/_models/PropertyMdl";
import dayjs, { Dayjs } from "dayjs";
import { s6 } from "_common/_utils/coreUtils";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { IAddressMdl } from "users/_models/UserMdl";
import { GoogleResponse, googleUri } from "_configs/googleConfig";
import { fetchUtils, TFilesData } from "_common/_utils/fetchUtils";
import { createFilesData, storeFile } from "_common/_utils/fileUtils";
import { TImage } from "_common/types/baseTypes";
import { unitsAdminStore } from "admin/units/_stores/unitsAdminStore";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { createDefaultUnit } from "units/_models/UnitMdl";
import { PLACEMENT_VISITED_EVENT, TPlacementStatEventModel } from "placements/_models/PlacementsMdl";
import { action, IObservableObject, observable } from "mobx";

export enum CRAWLED_PROPERTY_EVENT {
    VISITED = "properties/external/visited",
}

export class PropertiesAdminStore extends BaseResourceStore<TPropertyMdl> {
    @observable startDate: Dayjs = dayjs().startOf("day").add(-1, "day").add(-1, "week");
    @observable endDate: Dayjs = dayjs().endOf("day").add(-1, "day");

    constructor() {
        super("properties");
    }

    async create(item: Partial<TPropertyMdl>, files?: TFilesData) {
        if (!item) return;
        if (item.address) {
            const location = await this.getLocationFromAddress(item.address as IAddressMdl);
            item = { ...item, location };
        }
        return super.create(item, files);
    }

    async patch(item: Partial<TPropertyMdl>, files?: TFilesData) {
        if (!item) return;
        return super.patch(item, files);
    }

    async duplicate(itemId: string, photosItemId?: string): Promise<(TPropertyMdl & IObservableObject) | undefined> {
        let item = await this.getAsync(itemId);
        const itemForPhotos = photosItemId ? await this.getAsync(photosItemId) : item;
        if (!item || !itemForPhotos) throw { key: "ITEM_NOT_FOUND" };

        for (const lang in item.localized) {
            item.localized[lang as TLang].urlAlias = item.localized[lang as TLang].urlAlias + s6();
        }
        const { photos, filesData } = await this.duplicatePhotos(itemForPhotos.photos);

        item = {
            ...item,
            photos,
            floorPlans: [],
            price: { min: item.price.min * 100, max: (item.price?.max ?? 0) * 100 },
            stats: { printed: { count: 0, date: new Date() }, visited: { count: 0, date: new Date() } },
        };
        delete item._id;
        const newProperty = await this.duplicateItem(item, filesData);
        if (!newProperty) return undefined;
        const { items: units } = await unitsAdminStore.list(0, 200, undefined, undefined, [
            {
                id: "property",
                type: TFilterType.ID,
                value: photosItemId,
            },
        ]);
        if (units) {
            for (let unit of units) {
                const { photos, filesData } = await this.duplicatePhotos(unit.photos);

                unit = {
                    ...unit,
                    photos,
                    floorPlans: [],
                    price: { min: unit.price.min * 100, max: (unit.price?.max ?? 0) * 100 },
                };
                delete unit._id;
                delete unit.property;
                await unitsAdminStore.create(
                    {
                        ...createDefaultUnit(newProperty._id),
                        ...unit,
                    },
                    filesData,
                );
            }
        }
        return new Promise((resolve) => resolve(newProperty));
    }

    @observable totalStats: {
        [key: string]: TPlacementStatEventModel[];
    } = {
        [PLACEMENT_VISITED_EVENT.VISITED_PREMIUM]: [],
    };

    fetchPlacements() {
        return fetchUtils
            .post<{
                [key: string]: TPlacementStatEventModel[];
            }>(sharedConfig.apiUrl + `/analytics/stats/placements`, {
                period: {
                    startDate: this.startDate,
                    endDate: this.endDate,
                },
            })
            .then(
                action(({ data }) => {
                    this.totalStats = data;
                }),
            )
            .catch((e) => console.error(e));
    }

    async duplicatePhotos(item: TImage[]) {
        const photos = [];
        const photosForFileData = [];
        for (const photo of item) {
            const blobImg = await fetch(photo.url).then((image) => image.blob());
            const blobUrl = storeFile(blobImg);
            photosForFileData.push(blobUrl);
            photos.push({ url: blobUrl });
        }
        const filesData = await createFilesData(photosForFileData, `photos.*.url`, 1920);
        return { filesData, photos };
    }

    async getLocationFromAddress(address: IAddressMdl | TAddressMdl | string): Promise<TLocation | undefined> {
        const { data } = await fetchUtils.get<GoogleResponse>(googleUri.locationFromAddress(address), undefined, true);
        const { results } = data;
        if (results[0]) {
            return {
                type: "Point",
                coordinates: [results[0].geometry.location.lng, results[0].geometry.location.lat],
            } as TLocation;
        }
    }

    async getPropertiesFromRegion(regionId: string): Promise<{ items: TPropertyMdl[]; count: number }> {
        const { data } = await fetchUtils.get<TPropertyMdl[]>(this.apiPath + `/propertiesByRegions/${regionId}`);
        return { items: data, count: data.length };
    }
    protected reformatItem(item: TPropertyMdl) {
        const reformatedItem = super.reformatItem(item);
        return {
            ...reformatedItem,
            deliveryDates: reformatedItem.deliveryDates.map((date) => dayjs(date)),
            price: {
                min: reformatedItem.price.min / 100,
                max: reformatedItem.price.max ? reformatedItem.price.max / 100 : 0,
            },
        };
    }
}

const propertiesAdminStore = new PropertiesAdminStore();
export { propertiesAdminStore };
