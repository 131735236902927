import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./_css/virtualAssistantFinalStepForm.module.css";
import { VirtualAssistantQuestionLabelAndTitle } from "virtualAssistant/VirtualAssistantQuestionLabelAndTitle";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import { TVirtualAssistantForm } from "virtualAssistant/VirtualAssistantForm";
import { VirtualAssistantZonesDisplay } from "virtualAssistant/VirtualAssistantZonesDisplay";
import { WHEN_DATA } from "virtualAssistant/VIRTUAL_ASSISTANT_DATA";
import { VirtualAssistantPropertiesLoader } from "virtualAssistant/VirtualAssistantPropertiesLoader";
import { TFilter } from "admin/_common/filters/TFilter";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { VirtualAssistantNoResults } from "virtualAssistant/VirtualAssistantNoResults";
import { observer } from "mobx-react";

type Props = {
    filters: TFilter[];
    goToStepOne: () => void;
};
export const VirtualAssistantFinalStepForm = observer(({ filters, goToStepOne }: Props) => {
    const { t } = useTranslation();
    const { watch } = useFormContext<TVirtualAssistantForm>();
    const values = watch();

    const listStore = propertiesStore.getListStore("virtual-assistant-summary", undefined, filters);

    useEffect(() => {
        listStore.reload();
    }, []);

    return (
        <div className={"flex_column"}>
            <div className={styles.title}>{t("virtualAssistant.finalStepForm.questionnaireSummaryTitle")}</div>

            <VirtualAssistantQuestionLabelAndTitle
                questionLabel={t("virtualAssistant.stepOneForm.firstQuestionLabel")}
                questionTitle={t("virtualAssistant.stepOneForm.firstQuestionTitle")}
            />
            <div className={styles.answer}>{t(`virtualAssistant.stepOneForm.${values.purpose.toLowerCase()}`)}</div>

            <VirtualAssistantQuestionLabelAndTitle
                questionLabel={t("virtualAssistant.stepOneForm.secondQuestionLabel")}
                questionTitle={t("virtualAssistant.stepOneForm.secondQuestionTitle")}
            />
            <div className={styles.answer}>
                <VirtualAssistantZonesDisplay />
            </div>

            <VirtualAssistantQuestionLabelAndTitle
                questionLabel={t("virtualAssistant.stepTwoForm.thirdQuestionLabel")}
                questionTitle={t("virtualAssistant.stepTwoForm.thirdQuestionTitle")}
            />
            <div className={styles.answer}>
                {values.propertyType.map((type) => t(`virtualAssistant.stepTwoForm.${type.toLowerCase()}`)).join(", ")}
            </div>

            <VirtualAssistantQuestionLabelAndTitle
                questionLabel={t("virtualAssistant.stepTwoForm.fourthQuestionLabel")}
                questionTitle={t("virtualAssistant.stepTwoForm.fourthQuestionTitle")}
            />
            <div className={styles.answer}>
                {values.price[0].toString() === "" ? t("virtualAssistant.finalStepForm.noMin") : values.price[0]} -{" "}
                {values.price[1].toString() === "" ? t("virtualAssistant.finalStepForm.noMax") : values.price[1]}
            </div>

            <VirtualAssistantQuestionLabelAndTitle
                questionLabel={t("virtualAssistant.stepTwoForm.fifthQuestionLabel")}
                questionTitle={t("virtualAssistant.stepTwoForm.fifthQuestionTitle")}
            />
            <div className={styles.answer}>{`${t("virtualAssistant.finalStepForm.bedroom", {
                count: values.bedrooms[0],
            })}, ${t("virtualAssistant.finalStepForm.bathroom", {
                count: values.bathrooms[0],
            })}`}</div>

            <VirtualAssistantQuestionLabelAndTitle
                questionLabel={t("virtualAssistant.stepThreeForm.sixthQuestionLabel")}
                questionTitle={t("virtualAssistant.stepThreeForm.sixthQuestionTitle")}
            />
            <div className={styles.answer}>
                {values.surface[0].toString() === "" ? t("virtualAssistant.finalStepForm.noMin") : values.surface[0]} -{" "}
                {values.surface[1].toString() === "" ? t("virtualAssistant.finalStepForm.noMax") : values.surface[1]}
            </div>
            <VirtualAssistantQuestionLabelAndTitle
                questionLabel={t("virtualAssistant.stepThreeForm.seventhQuestionLabel")}
                questionTitle={t("virtualAssistant.stepThreeForm.seventhQuestionTitle")}
            />
            <div className={styles.answer}>{t(WHEN_DATA.find((data) => data.value === values.when)?.label)}</div>

            <VirtualAssistantQuestionLabelAndTitle
                questionLabel={t("virtualAssistant.stepThreeForm.eighthQuestionLabel")}
                questionTitle={t("virtualAssistant.stepThreeForm.eighthQuestionTitle")}
            />
            <div className={styles.answer}>
                {values.features.map((f, idx) => {
                    return (
                        <span key={f}>
                            {idx > 0 ? ", " : ""}
                            {t(`property.features.${f.split(".")[1]}`)}
                        </span>
                    );
                })}
            </div>

            <VirtualAssistantQuestionLabelAndTitle
                questionLabel={t("virtualAssistant.stepThreeForm.ninthQuestionLabel")}
                questionTitle={t("virtualAssistant.stepThreeForm.ninthQuestionTitle")}
            />
            <div className={styles.answer}>{values.comment}</div>

            <div className={styles.title}>{t("virtualAssistant.finalStepForm.userInfosTitle")}</div>
            <div className={styles.userInfosContainer}>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <div>{t("virtualAssistant.finalStepForm.firstName")}</div>
                        <div className={styles.answer}>{values.firstName}</div>
                    </div>
                    <div className={styles.column}>
                        <div>{t("virtualAssistant.finalStepForm.lastName")}</div>
                        <div className={styles.answer}>{values.lastName}</div>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <div>{t("virtualAssistant.finalStepForm.email")}</div>
                        <div className={styles.answer}>{values.email}</div>
                    </div>
                    <div className={styles.column}>
                        <div>{t("virtualAssistant.finalStepForm.phone")}</div>
                        <div className={styles.answer}>{values.phones}</div>
                    </div>
                </div>
            </div>

            <div className={"mt_50"}>
                {listStore.items.length === 0 ? (
                    <VirtualAssistantNoResults goToStepOne={goToStepOne} />
                ) : (
                    <VirtualAssistantPropertiesLoader filters={filters} />
                )}
            </div>
            <div className={clsx("flex_column", styles.agreementContainer)}>
                <div>{t("virtualAssistant.finalStepForm.agreementTitle")}</div>
                <div dangerouslySetInnerHTML={{ __html: t("virtualAssistant.finalStepForm.agreementText") }} />
            </div>
        </div>
    );
});
