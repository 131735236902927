import React from "react";
import styles from "./_css/virtualAssistantFormStepper.module.css";
import { useTranslation } from "react-i18next";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { ArrowCtaIcon } from "properties/propertyPage/_components/ArrowCtaIcon";
import { ArrowReverseCtaIcon } from "properties/propertyPage/_components/ArrowReverseCtaIcon";
import { useFormContext } from "react-hook-form";
import { VirtualAssistantFinalButton } from "virtualAssistant/VirtualAssistantFinalButton";
import clsx from "clsx";

export type TVirtualAssistantSummary = {
    name?: string;
    email?: string;
    url: string;
};

type Props = {
    onNext?: () => void;
    onPrevious?: () => void;
    step: number;
    filterUrl: string;
};

export function VirtualAssistantFormStepper({ onNext, onPrevious, step, filterUrl }: Props) {
    const { t } = useTranslation();
    const form = useFormContext();

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {onPrevious ? (
                    <div className="flex_row">
                        <ExternalOrNavLink
                            className={clsx(styles.btn, styles.btnOutlined)}
                            url={"#"}
                            onClick={() => onPrevious()}
                        >
                            <ArrowReverseCtaIcon /> {t("virtualAssistant.previous")}
                        </ExternalOrNavLink>
                    </div>
                ) : (
                    <div className={"flex-1"} />
                )}
                {onNext && step < 3 && (
                    <div className="flex_row">
                        <ExternalOrNavLink
                            className={clsx(styles.btn, styles.btnPrimary)}
                            url={"#"}
                            onClick={() => onNext()}
                        >
                            {t("virtualAssistant.next")} <ArrowCtaIcon />
                        </ExternalOrNavLink>
                    </div>
                )}

                {onNext && step === 3 && (
                    <div className="flex_row">
                        <button
                            onClick={() => onNext()}
                            disabled={Object.keys(form.formState.errors).length > 0}
                            className={clsx(styles.btn, styles.btnPrimary)}
                            type={"submit"}
                        >
                            {t("virtualAssistant.next")}
                            <ArrowCtaIcon />
                        </button>
                    </div>
                )}

                {step >= 4 && onNext && <VirtualAssistantFinalButton filterUrl={filterUrl} />}
            </div>
        </div>
    );
}
