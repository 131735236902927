import React from "react";
import { observer } from "mobx-react";
import { Paginator } from "admin/_common/list/Paginator";
import { ListStore } from "_common/list/ListStore";
import { getUrlWithPagination } from "_common/_utils/searchUtils";
import { useHistory } from "react-router-dom";

type Props = {
    listStore: ListStore<any>;
    className?: string;
};

export const PropertiesPaginator = observer((props: Props) => {
    const isShuffle = !props.listStore.sort;
    const history = useHistory();

    return (
        <Paginator
            onPageClick={(page) => {
                history.push(getUrlWithPagination(location.pathname, page) + location.search);
                props.listStore.setSelectedPage(page, isShuffle);
            }}
            count={props.listStore.count}
            selectedPage={props.listStore.selectedPage}
            pageSize={props.listStore.pageSize}
            className={props.className}
        />
    );
});
