import React, { lazy, useEffect, useRef } from "react";
import clsx from "clsx";
import styles from "../_css/propertiesView.module.css";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { PropertiesViewList } from "properties/listing/PropertiesViewList";
import { PropertyResetButton } from "properties/propertyResetButton";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import Suspenser from "_common/loaders/Suspenser";
import { scrollTop } from "_common/_utils/ScrollTop";
import { placementsStore } from "placements/_store/placementsStore";
import { getPlacementPropertyType } from "_common/_utils/placementUtils";
import { PropertiesSort } from "properties/listing/PropertiesSort";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { useTranslation } from "react-i18next";
import { usePropertiesPageContext } from "properties/PropertiesPageContext";
import { PropertiesViewButtonsMobile } from "properties/listing/PropertiesViewButtonsMobile";
import { observer } from "mobx-react";
import { PropertiesViewHeader } from "properties/listing/PropertiesViewHeader";

export const MAP_REF: { current: unknown | null } = { current: null };

export enum MAP_OR_LIST_SELECTED {
    "LIST" = "list",
    "MAP" = "map",
}

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

const LazyMapRender = lazy(() => import("maps/MapRender"));

export const PropertiesView = observer((props: Props) => {
    const propertiesStore = usePropertiesStore();

    const resultsContainer = useRef<HTMLDivElement>(null);
    const { mapOrListSelected } = usePropertiesPageContext();
    const { t } = useTranslation();

    if (propertiesStore.purpose) {
        placementsStore.fetchBannerProperties(
            getPlacementPropertyType(propertiesStore.purpose),
            undefined,
            geoZonesStore?.geoZone?._id,
        );
    }

    useEffect(() => {
        setTimeout(() => {
            if (resultsContainer && resultsContainer?.current?.scrollTop) {
                scrollTop(resultsContainer, resultsContainer.current.scrollTop);
            }
        }, 250);
    }, [props.listStore.selectedPage, mapOrListSelected]);

    const displayCountIfResultsOrNoGeozone =
        (!!props.listStore.count && props.listStore.count > 0) || !geoZonesStore.geoZone;

    const { cityForMeta } = useSearchPageParams();

    return (
        <>
            <div
                className={clsx("flex_row flex-1 position_relative", styles.container)}
                onClick={() => {
                    geoZonesStore.open(false);
                }}
                onMouseLeave={() => propertiesStore.setMapPropertiesSelected(undefined)}
            >
                <div
                    ref={resultsContainer}
                    className={clsx("flex_column", styles.results, {
                        [styles.listOnly]: mapOrListSelected === MAP_OR_LIST_SELECTED.LIST,
                        ["w-half"]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                        [styles.resultWithMap]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                    })}
                >
                    {mapOrListSelected === MAP_OR_LIST_SELECTED.MAP && (
                        <div className={"ph_20"}>
                            <PropertiesViewHeader listStore={props.listStore} />
                        </div>
                    )}
                    <div className={clsx(styles.contentResult, "ph_20 flex_row justifyContent_spaceBetween mb_10")}>
                        <PropertiesSort listStore={props.listStore} />
                        {displayCountIfResultsOrNoGeozone && (
                            <div className={clsx("flex_row_center", styles.textResult)}>
                                <span>
                                    {props.listStore.count} {t("words.results")}
                                </span>
                                {(geoZonesStore.geoZone?.address.neighbourhood ??
                                    geoZonesStore.geoZone?.address.city ??
                                    cityForMeta ??
                                    propertiesStore.addressParams.city) && (
                                    <span className={"hiddenMobile"}>
                                        {(geoZonesStore.geoZone?.address.city || cityForMeta) && (
                                            <span className={"ml_5"}>
                                                {t("words.for")}{" "}
                                                {geoZonesStore.geoZone?.address.neighbourhood ??
                                                    geoZonesStore.geoZone?.address.city ??
                                                    cityForMeta}
                                            </span>
                                        )}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    <PropertiesViewList listStore={props.listStore} />
                </div>
                {mapOrListSelected === MAP_OR_LIST_SELECTED.MAP && (
                    <div className={"flex_row flex-1 position_relative"}>
                        <Suspenser noSpinner noText>
                            <LazyMapRender listStore={props.listStore} />
                        </Suspenser>
                        {geoZonesStore.geoZone && (
                            <div className="position_absolute" style={{ bottom: 0, left: 0, margin: 10 }}>
                                <PropertyResetButton listStore={props.listStore} />
                            </div>
                        )}
                    </div>
                )}
                <PropertiesViewButtonsMobile listStore={props.listStore} />
            </div>
        </>
    );
});
