import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { refreshPage } from "_common/_utils/pageUtils";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import styles from "./_css/advancedResearches.module.css";
import clsx from "clsx";
import { Collapsible } from "_common/ui/components/Collapsible";
import { useWindowSize } from "_common/_utils/hookUtils";

type Props = {
    links: {
        to: string;
        label: string;
    }[];
};

function _AdvancedResearches({ links }: Props) {
    return (
        <Grid container spacing={1}>
            {links.map((link, i) => (
                <Grid key={i} item sm={12} md={6} lg={4} xl={3}>
                    <ExternalOrNavLink isBuyingOrIsRentingUrl={true} url={link.to} onClick={() => refreshPage(link.to)}>
                        <h3 className={clsx("fontWeight_normal", styles.link)}>{link.label}</h3>
                    </ExternalOrNavLink>
                </Grid>
            ))}
        </Grid>
    );
}
export function AdvancedResearches({ links }: Props) {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    if (links.length === 0) return null;
    const displayCollapse =
        width > 1750
            ? links.length > 20
            : width > 1280
            ? links.length > 15
            : width > 1000
            ? links.length > 10
            : links.length > 5;
    return (
        <div className={styles.container}>
            <h2 className={clsx(styles.title, "textAlign_center")}>{t("advancedResearches.title")}</h2>
            {displayCollapse ? (
                <Collapsible moreText={t("advancedResearches.seeMore")} heightOfCollapsable={150}>
                    <_AdvancedResearches links={links} />
                </Collapsible>
            ) : (
                <_AdvancedResearches links={links} />
            )}
        </div>
    );
}
