import { RefObject, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useJsApiLoader } from "@react-google-maps/api";
import { appConfig } from "_configs/appConfig";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { unitsStore } from "units/_stores/unitsStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { useLocation, useParams } from "react-router";
import sharedConfig from "_configs/sharedConfig";
import {
    getMapCoordinatesFromParams,
    PARAMS_NORTH_PREFIX,
    PARAMS_PAGE_PREFIX,
    PARAMS_ZOOM_PREFIX,
} from "_common/_utils/searchUtils";
import { isPropertyType } from "properties/searchBar/filters/_utils/filtersUtils";
import { getIsHomePage } from "_common/_utils/pageUtils";
import { useTranslation } from "react-i18next";
import { IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { TBreadcrumbItem } from "breadcrumb/Breadcrumb";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { reformatUrlForOriginalName } from "_common/_utils/alphaNumUtils";
import { URLS } from "_configs/URLS";
import { DASHBOARD_URLS } from "users/dashboard/_configs/DASHBOARD_URLS";
import { isIos } from "_common/_utils/deviceUtils";
import { propertyTypeKeyFromUrl } from "properties/searchBar/filters/_utils/propertyTypeUtils";

export function useGetItemsForBreadCrumb(page: IPageMdl) {
    let item: TBreadcrumbItem[] = [];
    const url = useLocation().pathname;

    const urlItems = url.split("/").slice(2);
    urlItems.pop();
    urlItems.forEach(() =>
        item.push({
            itemLabelKey: "breadcrumb.ourServices",
            url: "/" + i18nextInstance.language + "/" + urlItems,
        }),
    );
    if (page.type === PAGE_TYPE.ARTICLE) {
        item = [
            {
                itemLabelKey: "breadcrumb.blog",
                url: "/" + i18nextInstance.language + "/blog",
            },
        ];
    }
    return item;
}

export const useScrollPositionAlt = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset);
        };
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    return scrollPosition;
};

export function useScrollPosition(pageRef: { current: any }) {
    const [scrollPosition, setScrollPosition] = useState<number | undefined>(pageRef.current?.scrollTop);

    useEffect(() => {
        const ref = pageRef?.current ?? window;
        const handleScroll = () => {
            const position = ref.scrollTop;
            setScrollPosition(position);
        };
        ref.addEventListener("scroll", handleScroll, { passive: false });
        handleScroll();
        return () => {
            ref.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return scrollPosition;
}

export const useClickOutside = (
    containerRef: RefObject<HTMLDivElement> | null,
    callback: () => void,
    expectClass?: string,
) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const isExpectElement = expectClass ? (event.target as HTMLElement).classList.contains(expectClass) : false;
            if (
                containerRef &&
                containerRef.current &&
                !containerRef.current.contains(event.target as Node) &&
                !isExpectElement
            ) {
                callback();
            }
        };

        window.addEventListener("mousedown", handleClickOutside);
        return () => window.removeEventListener("mousedown", handleClickOutside);
    }, [containerRef, callback]);
};

export function useWindowSize(throttle?: number) {
    const [windowSize, setWindowSize] = useState({ width: -1, height: -1 });
    useEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        const listener = _.throttle(
            () =>
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                }),
            throttle ?? 100,
        );
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, []);
    return windowSize;
}

export function useMap() {
    const librariesRef = useRef(["places", "geometry"] as Libraries);
    const { isLoaded } = useJsApiLoader({
        id: "googleMap",
        libraries: librariesRef.current,
        googleMapsApiKey: appConfig.google.apiKey,
    });

    return { isLoaded };
}

export function useMousePosition() {
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });

    const updateMousePosition = _.debounce((ev) => setMousePosition({ x: ev.clientX, y: ev.clientY }), 200);

    useEffect(() => {
        !isIos ? window.addEventListener("mousemove", updateMousePosition) : undefined;

        return () => (!isIos ? window.removeEventListener("mousemove", updateMousePosition) : undefined);
    }, []);

    return mousePosition;
}

export function usePropertyOrUnit(type: MAIN_RESOURCE, itemId: string) {
    const itemOrFetch = MAIN_RESOURCE.PROPERTIES === type ? propertiesStore.get(itemId) : unitsStore.get(itemId);
    const { loading } = useLoadingFromPromise(
        "_id" in itemOrFetch ? new Promise((resolve) => resolve(itemOrFetch)) : itemOrFetch,
    );

    if ("_id" in itemOrFetch) {
        return { itemOrFetch, loading: undefined };
    }
    return { itemOrFetch, loading };
}

export function useSearchPageParams() {
    // === IS_CITY_PAGE ===
    // /region(p1)/city(p2)/neighbourhood(p3)/propertyType(p4)/page(p5)/zoom(p6)
    // /region(p1)/city(p2)/neighbourhood(p3)/propertyType(p4)/page(p5)
    // /region(p1)/city(p2)/neighbourhood(p3)/propertyType(p4)/zoom(p5)
    // /region(p1)/city(p2)/neighbourhood(p3)/page(p4)/zoom(p5)
    // /region(p1)/city(p2)/propertyType(p3)/page(p4)/zoom(p5)
    // /region(p1)/city(p2)/neighbourhood(p3)/page(p4)
    // /region(p1)/city(p2)/neighbourhood(p3)/zoom(p4)
    // /region(p1)/city(p2)/propertyType(p3)/page(p4)
    // /region(p1)/city(p2)/propertyType(p3)/zoom(p4)
    // /region(p1)/city(p2)/page(p3)/zoom(p4)
    // /region(p1)/city(p2)/neighbourhood(p3)
    // /region(p1)/city(p2)/page(p3)
    // /region(p1)/city(p2)/zoom(p3)
    // /region(p1)/propertyType(p2)
    // /region(p1)/city(p2)
    // /region(p1)

    // === !IS_CITY_PAGE ===
    // /propertyType(p1)/coordinates(p2)/page(p3)/zoom(p4)
    // /propertyType(p1)/coordinates(p2)/page(p3)
    // /propertyType(p1)/coordinates(p2)/zoom(p3)
    // /propertyType(p1)/coordinates(p2)
    // /coordinates(p1)/page(p2)
    // /coordinates(p1)/zoom(p2)
    // /coordinates(p1)

    const { p1, p2, p3, p4, p5, p6 } = useParams();
    const { i18n } = useTranslation();
    let cityForMeta,
        coordinates,
        region,
        city,
        neighborhood,
        propertyTypeLocalized,
        page,
        propertyTypeKey,
        zoom = "";
    const isProvincePage =
        p1 && !p1?.startsWith(PARAMS_NORTH_PREFIX) && !p1?.startsWith(PARAMS_PAGE_PREFIX) && !isPropertyType(p1);
    const isCityPage =
        isProvincePage &&
        p2 &&
        !p2?.startsWith(PARAMS_NORTH_PREFIX) &&
        !p2?.startsWith(PARAMS_PAGE_PREFIX) &&
        !isPropertyType(p2);

    const location = undefined;
    let isExistingPropertyType = false;
    let isNeighborhoodPage = false;
    if (isProvincePage) {
        region = p1;
        if (p6) {
            zoom = p6.replace(PARAMS_ZOOM_PREFIX, "");
        }

        if (p5) {
            if (p5.startsWith(PARAMS_ZOOM_PREFIX)) {
                zoom = p5.replace(PARAMS_ZOOM_PREFIX, "");
            } else if (p5.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p5.replace(PARAMS_PAGE_PREFIX, "");
            }
        }

        if (p4) {
            if (p4.startsWith(PARAMS_ZOOM_PREFIX)) {
                zoom = p4.replace(PARAMS_ZOOM_PREFIX, "");
            } else if (p4.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p4.replace(PARAMS_PAGE_PREFIX, "");
            } else {
                propertyTypeLocalized = p4;
                propertyTypeKey = propertyTypeKeyFromUrl(propertyTypeLocalized, i18n);
                isExistingPropertyType = !!propertyTypeKey;
            }
        }
        if (p3) {
            if (p3.startsWith(PARAMS_ZOOM_PREFIX)) {
                zoom = p3.replace(PARAMS_ZOOM_PREFIX, "");
            } else if (p3.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p3.replace(PARAMS_PAGE_PREFIX, "");
            } else if (!isPropertyType(p3)) {
                neighborhood = p3;
                isNeighborhoodPage = true;
            } else {
                propertyTypeLocalized = p3;
                propertyTypeKey = propertyTypeKeyFromUrl(propertyTypeLocalized, i18n);
                isExistingPropertyType = !!propertyTypeKey;
            }
        }

        if (p2) {
            if (isCityPage) {
                city = p2;
                region = p1;
                cityForMeta =
                    reformatUrlForOriginalName(p2).split(",").length > 0
                        ? reformatUrlForOriginalName(p2).split(",")[0]
                        : reformatUrlForOriginalName(p2);
            } else {
                propertyTypeLocalized = p2;
                propertyTypeKey = propertyTypeKeyFromUrl(propertyTypeLocalized, i18n);
                isExistingPropertyType = !!propertyTypeKey;
            }
        }
    } else {
        if (p4) {
            if (p4.startsWith(PARAMS_ZOOM_PREFIX)) {
                zoom = p4.replace(PARAMS_ZOOM_PREFIX, "");
            }
        }

        if (p3) {
            if (p3.startsWith(PARAMS_PAGE_PREFIX)) {
                zoom = p3.replace(PARAMS_ZOOM_PREFIX, "");
            } else if (p3.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p3.replace(PARAMS_PAGE_PREFIX, "");
            }
        }

        if (p2) {
            if (p2.startsWith(PARAMS_PAGE_PREFIX)) {
                zoom = p2.replace(PARAMS_ZOOM_PREFIX, "");
            } else if (p2.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p2.replace(PARAMS_PAGE_PREFIX, "");
            } else if (p2.startsWith(PARAMS_NORTH_PREFIX)) {
                const _coordinates = p1.split(",");
                coordinates = getMapCoordinatesFromParams(_coordinates);
                propertiesStore.searchParams.mapCoordinates = coordinates;
            }
        }

        if (p1) {
            if (p1.startsWith(PARAMS_NORTH_PREFIX)) {
                const _coordinates = p1.split(",");
                coordinates = getMapCoordinatesFromParams(_coordinates);
                propertiesStore.searchParams.mapCoordinates = coordinates;
            } else {
                propertyTypeLocalized = p1;
                propertyTypeKey = propertyTypeKeyFromUrl(propertyTypeLocalized, i18n);
                isExistingPropertyType = !!propertyTypeKey;
            }
        }
    }
    return {
        isCityPage,
        isProvincePage,
        isExistingPropertyType,
        isNeighborhoodPage,
        isGeoZonePage: isCityPage || isProvincePage || isNeighborhoodPage,
        location,
        cityForMeta,
        city,
        region,
        coordinates,
        propertyTypeLocalized,
        propertyTypeKey,
        neighborhood,
        page,
        zoom,
    };
}
export function useHomePage() {
    const url = useLocation().pathname;
    const urlSplit = url.split("/");
    // NB: "" + lang + "" => 3 args
    return (
        (urlSplit.length <= 3 && Object.keys(sharedConfig.languages).includes(urlSplit[1]) && !urlSplit[2]) ||
        url === "/"
    );
}

export function useBlogPage() {
    const url = useLocation().pathname;
    const urlSplit = url.split("/");
    const { t } = useTranslation();
    return urlSplit?.[2] === t("routes.blog");
}

export function useIsSuccessPage() {
    const url = useLocation().pathname;
    const urlSplit = url.split("/");
    const { t } = useTranslation();
    return urlSplit?.[2] === t("routes.demandSuccess");
}

export function useCommercialPage() {
    const { t } = useTranslation();
    const url = useLocation().pathname;
    const urlSplit = url.split("/");
    return (
        urlSplit.length <= 3 &&
        Object.keys(sharedConfig.languages).includes(urlSplit[1]) &&
        urlSplit[2] === t("routes.advertise")
    );
}

export function usePricePage() {
    const { t } = useTranslation();
    const url = useLocation().pathname;
    const urlSplit = url.split("/");
    return (
        urlSplit.length > 3 &&
        Object.keys(sharedConfig.languages).includes(urlSplit[1]) &&
        urlSplit[2] === t("routes.advertise")
    );
}

export function usePage() {
    const url = useLocation().pathname;
    const isHomePage = getIsHomePage(url);
    const isRentOrBuyPage = url.startsWith(URLS.buy()) || url.startsWith(URLS.rent());
    const isPropertyUnitPage = url.startsWith(URLS.property(""));
    const isDashboardPage = url.startsWith(DASHBOARD_URLS.home());
    const isVirtualAssistantPage = url.startsWith(URLS.virtualAssistant());
    const isSummaryOrderPageOrProjectPageForm = url.startsWith(URLS.resume()) || url.startsWith(URLS.project(""));
    const isCommercialPage = useCommercialPage();
    const isPricePage = usePricePage();
    return {
        isHomePage,
        isVirtualAssistantPage,
        isRentOrBuyPage,
        isPropertyUnitPage,
        isCommercialPage,
        isPricePage,
        isDashboardPage,
        isSummaryOrderPageOrProjectPageForm,
    };
}
