import React, { useContext, useEffect, useState } from "react";
import { MAP_OR_LIST_SELECTED } from "properties/listing/PropertiesView";

const PropertiesPageContext = React.createContext(
    {} as {
        mapOrListSelected: MAP_OR_LIST_SELECTED;
        setMapOrListSelected: (mapOrList: MAP_OR_LIST_SELECTED) => void;
    },
);

export function usePropertiesPageContext() {
    return useContext(PropertiesPageContext);
}

export function PageRendererContext(props: React.PropsWithChildren<any>) {
    const [mapOrListSelected, setMapOrListSelected] = useState<MAP_OR_LIST_SELECTED>(MAP_OR_LIST_SELECTED.LIST);
    useEffect(() => {
        if (mapOrListSelected) {
            localStorage.setItem("displayListing", mapOrListSelected);
        }
    }, [mapOrListSelected]);
    return (
        <PropertiesPageContext.Provider
            value={{
                mapOrListSelected,
                setMapOrListSelected,
            }}
        >
            {props.children}
        </PropertiesPageContext.Provider>
    );
}
