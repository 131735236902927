import React from "react";
import { ListStore } from "_common/list/ListStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import styles from "../_css/propertiesViewButtonsMobile.module.css";
import clsx from "clsx";
import { SavedSearchButton } from "properties/searchBar/savedSearchButton";
import { PropertiesViewButtonMapList } from "properties/listing/PropertiesViewButtonMapList";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export function PropertiesViewButtonsMobile({ listStore }: Props) {
    return (
        <div className={clsx(styles.container, "flex_row_center justifyContent_center gap_20")}>
            <PropertiesViewButtonMapList />
            <SavedSearchButton listStore={listStore} />
        </div>
    );
}
