import React from "react";
import { IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { ComponentRenderer } from "components/ComponentRenderer";
import { PageRendererContext } from "pages/PageContext";
import { PageRendererStore } from "pages/_stores/PageRendererStore";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";
import { Container } from "@material-ui/core";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { PageMeta } from "pages/PageMeta";
import { useGetItemsForBreadCrumb, useHomePage } from "_common/_utils/hookUtils";

type Props = {
    page: IPageMdl;
};

export function PageRenderer(props: Props) {
    const isHomePage = useHomePage();
    const items = useGetItemsForBreadCrumb(props.page);
    const localizedPage = props.page.localized[i18nextInstance.language as TLang];
    if (!localizedPage) return null;

    const isBlogPage = props.page.type === PAGE_TYPE.ARTICLE;
    if (isBlogPage) {
        return <BlogPage page={props.page} />;
    }

    return (
        <PageRendererContext pageRendererStore={new PageRendererStore()}>
            <PageMeta page={props.page} />
            {!isHomePage && (
                <Container className={"mt_10"}>
                    <Breadcrumb currentItem={{ itemLabel: localizedPage.title }} items={items} />
                </Container>
            )}
            {localizedPage.component && <ComponentRenderer component={localizedPage.component} isRoot />}
        </PageRendererContext>
    );
}

function BlogPage(props: Props) {
    const localizedPage = props.page.localized[i18nextInstance.language as TLang];
    const items = useGetItemsForBreadCrumb(props.page);
    if (!localizedPage || !localizedPage.component) return null;

    return (
        <PageRendererContext pageRendererStore={new PageRendererStore()}>
            <PageMeta page={props.page} />
            <div style={{ width: "100%" }}>
                <div style={{ margin: "auto", maxWidth: 900 }}>
                    <Container className={"mt_10"}>
                        <Breadcrumb currentItem={{ itemLabel: localizedPage.title }} items={items} />
                    </Container>
                </div>
            </div>
            <div id="blogPage" style={{ width: "100%", paddingBottom: 60 }}>
                <div style={{ margin: "auto", maxWidth: 700 }}>
                    <ComponentRenderer component={localizedPage.component} isRoot />
                </div>
            </div>
        </PageRendererContext>
    );
}
