import React from "react";
import { useLocation } from "react-router";
import { getFiltersFromUrl } from "properties/searchBar/filters/_utils/filtersUtils";
import { TFilter } from "admin/_common/filters/TFilter";
import { VirtualAssistantSummaryLoader } from "virtualAssistant/VirtualAssistantSummaryLoader";
import styles from "virtualAssistant/_css/virtualAssistantSummaryPage.module.css";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";

export const VirtualAssistantSummaryPage = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const query = new URLSearchParams(location.search);
    const initialFilters: TFilter[] = [];

    const filters = getFiltersFromUrl(initialFilters, Object.fromEntries(query.entries()));

    return (
        <div className={styles.container}>
            <Container>
                <h2 className={styles.title}>{t("virtualAssistantSummary.title")}</h2>
                <div>{t("virtualAssistantSummary.subtitle")}</div>
                <div className={styles.summaryContainer}>
                    <VirtualAssistantSummaryLoader filters={filters} />
                </div>
                <div className={styles.buttonContainer}>
                    <ExternalOrNavLink url={"/"} className={styles.button}>
                        {t("virtualAssistantSummary.continueSearching")}
                    </ExternalOrNavLink>
                </div>
            </Container>
        </div>
    );
};
