import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { ListStore } from "_common/list/ListStore";
import { PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { capitalize, reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import i18next from "i18next";

export enum FILTER_TYPE {
    propertyType = "propertyType",
    default = "default",
}

export enum PROPERTY_FILTERS {
    SQUARE_SURFACE_MAX = "squareSurface.max",
    SQUARE_SURFACE_MIN = "squareSurface.min",
    BATHROOMS_MAX = "bathrooms.max",
    BATHROOMS_MIN = "bathrooms.min",
    BEDROOMS_MAX = "bedrooms.max",
    BEDROOMS_MIN = "bedrooms.min",
    PRICE_MAX = "price.max",
    PRICE_MIN = "price.min",
    TYPE = "type",
    LOCATION = "location",
    PURPOSE = "purpose",
    AMENITIES = "amenities",
    FEATURES = "features",
    STATUS = "status",
    UNIT_TYPE = "typeUnit",
    PRICE_DISPLAY = "priceIsNotDisplayed",
}

export const UNIT_SPECIFIC_FILTERS = [
    PROPERTY_FILTERS.BATHROOMS_MAX,
    PROPERTY_FILTERS.BATHROOMS_MIN,
    PROPERTY_FILTERS.BEDROOMS_MAX,
    PROPERTY_FILTERS.BEDROOMS_MIN,
    PROPERTY_FILTERS.PRICE_MAX,
    PROPERTY_FILTERS.PRICE_MIN,
    PROPERTY_FILTERS.SQUARE_SURFACE_MAX,
    PROPERTY_FILTERS.SQUARE_SURFACE_MIN,
];

export function isPropertyType(supposedPropertyType: string) {
    const allTypes = Object.values(PROPERTY_TYPE);
    const allPropertiesInAllLang: string[] = [];
    allTypes.map((type) =>
        allPropertiesInAllLang.push(
            reformatStringForUrls(getI18nExpByLang(i18next.language, `property.typesUrl.${type}`)),
        ),
    );
    return allPropertiesInAllLang.includes(supposedPropertyType);
}

export function getFiltersValueKey(filters: TFilter[], filterKeys: PROPERTY_FILTERS[]): TFilter[] {
    return filters
        .filter((filter) => filterKeys.some((filterKey) => filter.id.includes(filterKey)))
        .sort((a, b) => b.id.includes("max") - a.id.includes("max"));
}

export function getLabelFilterValue(filter: TFilter) {
    const { id: filterKey, value } = filter;
    if (filterKey === "type") {
        return capitalize(value?.[0]);
        // return getI18nExpByLang(i18next.language, `property.types.${value}`);
    }
    if (filterKey.startsWith("bedroom")) {
        if (parseInt(value) === 0) return "Studio...";
        return `${value} ${getI18nExpByLang(i18next.language, "searchBar.filter.bedrooms")}...`;
    }
    if (filterKey.startsWith("bathroom")) {
        return `${value} ${getI18nExpByLang(i18next.language, "searchBar.filter.bathrooms")}...`;
    }
    if (filterKey.startsWith("price")) {
        if (parseInt(value) > 10000000) {
            return `${parseInt(value) / 100000}K...`;
        }
        return `${parseInt(value) / 100}...`;
    }
    if (filterKey.startsWith("surface")) {
        return `${value}...`;
    }
    if (filterKey.startsWith("status")) {
        return `${getI18nExpByLang(i18next.language, `propertyPage.${value}`)}...`;
    }
    if (filterKey.startsWith("amenities") || filterKey.startsWith("features")) {
        return `${getI18nExpByLang(i18next.language, `property.features.${filterKey.split(".")[1]}`)}...`;
    }
    return value;
}

export const updateSearchFilter = (listStore: ListStore<TPropertyListingMdl>, filters: TFilter[], reload = true) => {
    return new Promise((resolve) => {
        const locationIndex = filters.findIndex((filter) => filter.id === "location");
        const newFiltersIds = filters.map((filter) => filter.id);

        listStore.updateFilters([
            ...listStore.filters
                .filter((filter) => {
                    if (locationIndex > -1) {
                        return filter.id !== "location";
                    }
                    return true;
                })
                .filter((filter) => !newFiltersIds.includes(filter.id)),
            ...filters.filter(
                (filter) =>
                    ((filter.id.startsWith("amenities") || filter.id.startsWith("features")) && filter.value) ||
                    !(filter.id.startsWith("amenities") || filter.id.startsWith("features")),
            ),
        ]);
        if (reload) listStore.reload();
        resolve(listStore);
    });
};

export function getFeaturesOrAmenitiesFilters(filters: TFilter[]) {
    return filters.filter((filter) => filter.id.startsWith("amenities") || filter.id.startsWith("features"));
}

export function getUrlSearchFromFilter(
    filterType = FILTER_TYPE.default,
    search: string,
    filter?: TFilter,
    deleteFilter = false,
) {
    const searchParams = new URLSearchParams(search);
    switch (filterType) {
        case FILTER_TYPE.propertyType:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                }
                searchParams.set(filter.id, filter.value.join(","));
            }
            return searchParams.toString();
        case FILTER_TYPE.default:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                }
            }
            if (deleteFilter) return searchParams.toString();
            if (filter) searchParams.set(filter.id, filter.value.toString());
            return searchParams.toString();
        default:
            return searchParams.toString();
    }
}

export function getUrlSearchFromFilters(filters: TFilter[]) {
    let search = "";
    filters.forEach((filter) => {
        switch (filter.id) {
            case PROPERTY_FILTERS.PURPOSE:
            case PROPERTY_FILTERS.LOCATION:
                break;
            case PROPERTY_FILTERS.TYPE:
                search = getUrlSearchFromFilter(FILTER_TYPE.propertyType, search, filter);
                break;
            default:
                search = getUrlSearchFromFilter(FILTER_TYPE.default, search, filter);
        }
    });
    return search;
}

export function getFiltersFromQuery(query: { [key: string]: any }) {
    const filterFromQuery: TFilter[] = [];
    const filterType: string[] = [];
    Object.entries(query).forEach(([filterKey, filterValue]) => {
        if (filterKey.startsWith(PROPERTY_FILTERS.SQUARE_SURFACE_MAX)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.SQUARE_SURFACE_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "gte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.SQUARE_SURFACE_MIN)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.SQUARE_SURFACE_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "lte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.BATHROOMS_MAX)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.BATHROOMS_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "gte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.BATHROOMS_MIN)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.BATHROOMS_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "lte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.BEDROOMS_MAX)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.BEDROOMS_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "gte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.BEDROOMS_MIN)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.BEDROOMS_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "lte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.PRICE_MAX)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.PRICE_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "gte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.PRICE_MIN)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.PRICE_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue),
                op: "lte",
            });
        } else if (filterKey.startsWith(PROPERTY_FILTERS.TYPE)) {
            (filterValue.split(",") as string[]).map((filterValue) => filterType.push(filterValue));
        } else if (filterKey.startsWith(PROPERTY_FILTERS.STATUS)) {
            filterFromQuery.push({
                id: PROPERTY_FILTERS.STATUS,
                type: TFilterType.ENUM,
                value: filterValue,
            });
        } else if (filterKey.startsWith("amenities.")) {
            const amenityKey = filterKey.slice("amenities.".length);
            const id = `amenities.${amenityKey}`;
            filterFromQuery.push({
                id,
                value: filterValue,
                type: TFilterType.BOOLEAN,
            });
        } else if (filterKey.startsWith("features.")) {
            const featureKey = filterKey.slice("features.".length);
            const id = `features.${featureKey}`;
            filterFromQuery.push({
                id,
                value: filterValue,
                type: TFilterType.BOOLEAN,
            });
        } else if (filterKey.startsWith("purpose")) {
            filterFromQuery.push({
                id: "purpose",
                value: filterValue,
                type: TFilterType.STRING,
            });
        } else if (filterKey.startsWith("geozone")) {
            filterFromQuery.push({
                id: "location",
                value: filterValue.split(","),
                type: TFilterType.ZONES,
            });
        }
    });
    return { filterFromQuery, filterType };
}

export function getFiltersFromUrl(initialFilters: TFilter[], query: { [key: string]: any }) {
    const initFilters = initialFilters.filter(
        (initialFilter) => initialFilter.id === "purpose" || initialFilter.id === "location",
    );
    const { filterFromQuery, filterType } = getFiltersFromQuery(query);
    const filtersFromUrl = initFilters.concat(filterFromQuery);
    if (filterType.length > 0) {
        filtersFromUrl.push({
            id: PROPERTY_FILTERS.TYPE,
            type: TFilterType.IN,
            value: filterType,
        });
    }
    return filtersFromUrl;
}

export function getIsCommercialFilter(filter: TFilter[]) {
    return filter.some(
        (filter) =>
            filter.id === "type" &&
            filter.value.length === 1 &&
            filter.value.find((type: PROPERTY_TYPE) => type === PROPERTY_TYPE.commercial),
    );
}
