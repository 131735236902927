import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import sharedConfig from "_configs/sharedConfig";
import styles from "./_css/propertyDeveloper.module.css";
import { useTranslation } from "react-i18next";

export function PropertyDeveloper() {
    const propertyStore = usePropertyStore();
    let utm = "";
    const { t } = useTranslation();
    const developerSite = propertyStore.property?.propertyBuilderWebsite || propertyStore.property?.developer?.website;
    console.log(
        propertyStore.property,
        propertyStore.property.source?.platform ? sharedConfig.utm.queryStringScrap : sharedConfig.utm.queryString,
        developerSite,
    );
    if (!developerSite) return null;
    if (!developerSite.includes("utm_source=")) {
        utm = propertyStore.property.source?.platform
            ? sharedConfig.utm.queryStringScrap
            : sharedConfig.utm.queryString;
        utm = developerSite.endsWith("/") ? utm : `/${utm}`;
    }
    return (
        <a className={styles.link} href={developerSite + utm} target="_blank" rel="noopener noreferrer nofollow">
            <div className={styles.text}>
                {`${t("words.by")} ${
                    propertyStore.property?.propertyBuilderName ??
                    propertyStore.property.developer?.companyName ??
                    propertyStore.property.developer?.firstName + " " + propertyStore.property.developer?.lastName
                }`}
            </div>
        </a>
    );
}
