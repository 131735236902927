import React from "react";
import clsx from "clsx";
import styles from "./_css/virtualAssistantQuestionLabelAndTitle.module.css";

type Props = {
    questionLabel: string;
    questionTitle: string;
};

export function VirtualAssistantQuestionLabelAndTitle({ questionLabel, questionTitle }: Props) {
    return (
        <div className={clsx("flex_row", styles.container)}>
            <div>
                {questionLabel}. {questionTitle}
            </div>
        </div>
    );
}
