import React from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";

type Props = {
    goToStepOne: () => void;
};

export const VirtualAssistantNoResults = ({ goToStepOne }: Props) => {
    const { t } = useTranslation();
    return (
        <div className={"textAlign_center"}>
            <h3 className={"mb_1"}>{t("virtualAssistant.finalStepForm.noResultsTitle")}</h3>
            <div>{t("virtualAssistant.finalStepForm.noResultsText")}</div>
            <UiButton variant={"contained"} color={"primary"} onClick={goToStepOne} className={"mt_20"}>
                {t("virtualAssistant.finalStepForm.noResultsButton")}
            </UiButton>
        </div>
    );
};
