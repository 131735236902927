import React from "react";
import styles from "main/footer/_css/footerLinkList.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

export type TList = {
    url: string;
    labelKey: string;
};

type Props = {
    list: TList[];
    titleLabel: string;
};

export function FooterLinkList(props: Props) {
    const { t } = useTranslation();
    return (
        <div>
            <h3 className={clsx("mb_10", styles.title)}>{props.titleLabel}</h3>
            <ul className={styles.blockList}>
                {props.list.map((link, key) => {
                    if (t(link.labelKey) === "NO_LINK") return null;
                    return (
                        <li key={key} className={clsx(styles.list, "mb_10")}>
                            <Link target={"_blank"} rel="noopener noreferrer" to={link.url}>
                                {t(link.labelKey)}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
