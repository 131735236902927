import React from "react";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useFormContext } from "react-hook-form";
import { TVirtualAssistantForm } from "virtualAssistant/VirtualAssistantForm";
import { capitalize } from "_common/_utils/alphaNumUtils";

export function VirtualAssistantZonesDisplay() {
    const { loading } = useLoadingFromPromise(geoZonesStore.fetchAllGeoZones());
    const { watch } = useFormContext<TVirtualAssistantForm>();
    const values = watch();

    if (!loading) return null;

    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(_status, error, data) => {
                if (error || !data) {
                    return <ErrorBlock error={error} />;
                }
                const { items: geozones } = data.data;
                const geozonesFiltered = geozones
                    .filter((geozone) => values.geoZone.includes(geozone._id))
                    .map((geozone) => {
                        return capitalize(geozone.name.split(",")[0]);
                    });
                return <div>{geozonesFiltered.join(", ")}</div>;
            }}
        />
    );
}
