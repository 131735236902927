import { PROPERTY_PURPOSE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { action, computed, observable } from "mobx";
import { getSortedUnits, kindOfUnitBedroomsForProperty } from "_common/_utils/propertyUtils";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { getInitialStateValue, putPromiseResultInInitialState } from "_common/_utils/initialStateUtils";
import sharedConfig from "_configs/sharedConfig";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import i18next from "i18next";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TOpItems } from "admin/_common/filters/OpPicker";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";

export class PropertyStore {
    @observable property: TPropertyListingMdl;
    @observable isGalleryOpened = -1;
    @observable openedGalleryTab = GALLERY_TABS.GALLERY;

    @observable similarPropertiesState = new LoadingStateMdl<TPropertyListingMdl[]>();
    @observable similarPremiumPropertiesState = new LoadingStateMdl<TPropertyListingMdl[]>();
    @observable unitsState = new LoadingStateMdl<TUnitListingMdl[]>();
    @observable similarProperties: TPropertyListingMdl[] = [];
    @observable similarPremiumProperties: TPropertyListingMdl[] = [];
    @observable units: TUnitListingMdl[] = [];
    @observable imageModalIsOpen = -1;
    @observable typeOfImage: "photos" | "floorPlans" = "photos";

    constructor(property: TPropertyListingMdl) {
        this.property = property;
        this.onInit();
    }

    @computed get isForBuyingPurpose() {
        return this.property.purpose === PROPERTY_PURPOSE.BUY;
    }

    @computed get isForRentingPurpose() {
        return this.property.purpose === PROPERTY_PURPOSE.RENT;
    }

    @computed get sortedUnits() {
        return getSortedUnits(this.units);
    }

    @computed get kindOfBedrooms() {
        return kindOfUnitBedroomsForProperty(this.property);
    }

    @action setOpenedImageModal(typeOfImage: "photos" | "floorPlans", imageIndex?: number) {
        this.typeOfImage = typeOfImage;
        this.imageModalIsOpen = imageIndex ?? 0;
    }

    @action closeImageModal() {
        this.imageModalIsOpen = -1;
    }

    @action openGallery(imageIdx = 0, tabName = GALLERY_TABS.GALLERY) {
        this.setOpenedGalleryTab(tabName);
        this.isGalleryOpened = imageIdx;
    }

    @action setOpenedGalleryTab(tabName = GALLERY_TABS.GALLERY) {
        this.openedGalleryTab = tabName;
    }

    @action fetchUnitsOfProperty() {
        if (!this.unitsState.isLoading && !this.unitsState.isSucceeded) {
            this.unitsState.startLoading();
            const promise = fetchUtils.get<TUnitListingMdl[]>(
                `${sharedConfig.apiUrl}/units/property/${this.property._id}?lang=${i18next.language}`,
            );
            promise.then(
                ({ data }) => {
                    this.unitsState.setSuccess(data);
                    this.units = data;
                },
                (error) => {
                    this.unitsState.setError(error);
                },
            );
            putPromiseResultInInitialState("propertyUnits", promise);
        }
    }

    @action fetchSimilarProperties(premium: boolean, propertyId: string) {
        if (
            (!premium && this.similarPropertiesState.isIdle) ||
            (premium && this.similarPremiumPropertiesState.isIdle)
        ) {
            premium ? this.similarPremiumPropertiesState.startLoading() : this.similarPropertiesState.startLoading();
            const promise = propertiesStore.similarList(this.property.type, this.property.address.city, [
                {
                    id: "_id",
                    value: propertyId,
                    type: TFilterType.NE,
                },
                {
                    id: "premium.premiumPlan",
                    value: 0,
                    type: TFilterType.NUMBER,
                    op: premium ? "gt" : ("eq" as TOpItems),
                },
                {
                    id: "purpose",
                    value: this.property.purpose,
                    type: TFilterType.STRING,
                },
            ]);
            promise.then(
                ({ items }) => {
                    if (premium) {
                        this.similarPremiumPropertiesState.setSuccess(items);
                        this.similarPremiumProperties = items;
                    } else {
                        this.similarPropertiesState.setSuccess(items);
                        this.similarProperties = items;
                    }
                },
                (error) => {
                    if (premium) this.similarPremiumPropertiesState.setError(error);
                    else this.similarPropertiesState.setError(error);
                },
            );
        }
    }

    closeGallery() {
        this.isGalleryOpened = -1;
    }

    getDemographicsData(): Promise<{
        data: {
            data: {
                attributes: {
                    [key: string]: {
                        variables: { variable: string; value: number }[];
                    };
                };
            };
        };
        response: Response;
    }> {
        const params = {
            lat: this.property.location.coordinates[1],
            lng: this.property.location.coordinates[0],
            key: sharedConfig.localLogic.tokenDemographic,
        };
        return fetchUtils.get(
            `${sharedConfig.localLogic.urlDemographic}?lat=${params.lat}&lng=${params.lng}&key=${params.key}`,
            undefined,
            true,
        );
    }

    private onInit() {
        const initialUnitsState: { data: TUnitListingMdl[] } | undefined = getInitialStateValue("propertyUnits");
        this.units = [];
        if (initialUnitsState) {
            const { data: items } = initialUnitsState;
            this.units = items;
            this.unitsState.setSuccess(items);
        }
    }
}
