import React from "react";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import clsx from "clsx";
import styles from "virtualAssistant/_css/virtualAssistantFormStepper.module.css";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { notifierStore } from "components/misc/contactUs/_stores/notifierStore";
import { userStore } from "users/_stores/userStore";
import { useHistory } from "react-router";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

type Props = {
    filterUrl: string;
};

export const VirtualAssistantFinalButton = observer(({ filterUrl }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const form = useFormContext();
    const listStore = propertiesStore.getListStore("virtual-assistant-summary");
    return (
        <div className="flex_row">
            <ExternalOrNavLink
                className={clsx(styles.btn, {
                    [styles.btnOutlined]: listStore.items.length === 0,
                    [styles.btnPrimary]: listStore.items.length > 0,
                })}
                disabled={listStore.items.length === 0}
                url={filterUrl}
                onClick={() => {
                    if (listStore.items.length === 0) {
                        console.log("no results, RETURN");
                        return;
                    }
                    void notifierStore.assistantVirtualSummary({
                        name: `${form.watch("firstName")} ${form.watch("lastName")}`,
                        email: form.watch("email") ?? userStore.user?.email,
                        url: filterUrl,
                    });
                    void notifierStore.assistantVirtualNotifications(
                        propertiesStore
                            .getListStore("virtual-assistant-summary")
                            .paginatedItems.filter(Boolean)
                            .map((p) => p?._id) as string[],
                        {
                            firstName: form.watch("firstName"),
                            lastName: form.watch("lastName"),
                            email: form.watch("email"),
                            phone: form.watch("phone"),
                            comment: form.watch("comment"),
                        },
                    );
                    history.push(filterUrl);
                }}
            >
                {t("virtualAssistant.send")}
            </ExternalOrNavLink>
        </div>
    );
});
