import React from "react";
import { PROPERTY_PURPOSE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { JsonLdBreadCrumb } from "breadcrumb/JsonLdBreadCrumb";
import { useTranslation } from "react-i18next";
import { TFilter } from "admin/_common/filters/TFilter";
import { ListStore } from "_common/list/ListStore";

export function PropertiesBreadcrumb({ listStore }: { listStore: ListStore<TPropertyListingMdl> }) {
    const propertiesStore = usePropertiesStore();
    let propertyType;
    const type = listStore.filters.find((filter: TFilter) => filter.id === "type");
    if (type) {
        const filters = JSON.parse(JSON.stringify(type.value));
        propertyType = filters[0];
    }
    const { isCityPage, isNeighborhoodPage } = useSearchPageParams();
    const { t } = useTranslation();
    const { geoZone } = geoZonesStore;
    if (!geoZone) return null;
    const items = [];

    let currentPageTitle = t(
        `propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyType ?? "generic"),
        {
            geoZoneName: geoZone?.address?.province,
        },
    );

    if (isCityPage) {
        items.push({
            itemLabel: geoZone.address.province,
            url: URLS[propertiesStore.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                undefined,
                reformatStringForUrls(geoZone.address.province),
                propertyType,
            ),
            forceReload: true,
            pageTitle: t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyType ?? "generic"), {
                geoZoneName: t("provinces." + geoZone?.address.province),
            }),
        });
        currentPageTitle = t(
            `propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyType ?? "generic"),
            {
                geoZoneName: geoZone?.address?.city,
            },
        );
    }

    if (isNeighborhoodPage) {
        items.push({
            itemLabel: geoZone.address.city,
            url: URLS[propertiesStore.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                undefined,
                reformatStringForUrls(geoZone.address.province),
                reformatStringForUrls(geoZone.address.city),
                propertyType,
            ),
            forceReload: true,
            pageTitle: t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyType ?? "generic"), {
                geoZoneName: geoZone?.address?.city,
            }),
        });
        currentPageTitle = t(
            `propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyType ?? "generic"),
            {
                geoZoneName: geoZone?.address?.neighbourhood,
            },
        );
    }

    return (
        <JsonLdBreadCrumb
            items={items}
            currentItem={{
                itemLabel: currentPageTitle,
            }}
        />
    );
}
