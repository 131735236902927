import React from "react";
import { VirtualAssistantQuestionLabel } from "virtualAssistant/VirtualAssistantQuestionLabel";
import styles from "virtualAssistant/_css/virtualAssistantStepOneForm.module.css";
import { useTranslation } from "react-i18next";
import { CheckBoxInput } from "_common/ui/forms/CheckBoxInput";
import { Controller, useFormContext } from "react-hook-form";
import { TVirtualAssistantForm } from "virtualAssistant/VirtualAssistantForm";
import { RangeSelect } from "virtualAssistant/RangeSelect";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import clsx from "clsx";
import { PRICE_DATA } from "properties/searchBar/filters/_utils/priceUtils";
import { PROPERTY_PURPOSE, PROPERTY_TYPE_BY_PURPOSE } from "properties/_models/PropertyMdl";

export function VirtualAssistantStepTwoForm() {
    const form = useFormContext<TVirtualAssistantForm>();
    const { t } = useTranslation();

    const handleMinChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const [, max] = form.getValues("price");
        form.setValue("price", [event.target.value as number, max]);
    };

    const handleMaxChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const [min] = form.getValues("price");
        form.setValue("price", [min, event.target.value as number]);
    };

    const purpose: string = form.watch("purpose");
    const propertyTypeItems = PROPERTY_TYPE_BY_PURPOSE[purpose as PROPERTY_PURPOSE].map((type) => ({
        value: type,
        label: t(`virtualAssistant.stepTwoForm.${type}`),
    }));

    return (
        <div className={styles.container}>
            <div>
                <VirtualAssistantQuestionLabel label={t("virtualAssistant.stepTwoForm.thirdQuestionLabel")} />
                <div className={styles.title}>{t("virtualAssistant.stepTwoForm.thirdQuestionTitle")}</div>
                <Controller
                    as={CheckBoxInput}
                    multiple
                    control={form.control}
                    name={"propertyType"}
                    rules={{
                        required: t<string>("errors.forms.required"),
                    }}
                    items={propertyTypeItems}
                    containerClassName={styles.checkBoxInput}
                />
            </div>
            <div>
                <VirtualAssistantQuestionLabel label={t("virtualAssistant.stepTwoForm.fourthQuestionLabel")} />
                <div className={styles.title}>{t("virtualAssistant.stepTwoForm.fourthQuestionTitle")}</div>
                <div className={styles.budgetContainer}>
                    <div>
                        <Controller
                            name="price"
                            control={form.control}
                            defaultValue={[0, 2000000]}
                            render={({ value }) => (
                                <RangeSelect
                                    options={{
                                        min:
                                            PRICE_DATA[form.watch("purpose")?.toUpperCase() as PROPERTY_PURPOSE].default
                                                .min,
                                        max:
                                            PRICE_DATA[form.watch("purpose")?.toUpperCase() as PROPERTY_PURPOSE].default
                                                .max,
                                    }}
                                    minValue={value[0]}
                                    maxValue={value[1]}
                                    handleMinChange={handleMinChange}
                                    handleMaxChange={handleMaxChange}
                                    minLabel="virtualAssistant.stepTwoForm.minBudget"
                                    maxLabel="virtualAssistant.stepTwoForm.maxBudget"
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Controller
                            name="allowPriceUponRequestProject"
                            control={form.control}
                            defaultValue={false}
                            as={
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            onChange={(e) => {
                                                form.setValue("allowPriceUponRequestProject", e.target.checked);
                                            }}
                                        />
                                    }
                                    label={t("virtualAssistant.stepTwoForm.allowOnDemand")}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
            <div>
                <VirtualAssistantQuestionLabel label={t("virtualAssistant.stepTwoForm.fifthQuestionLabel")} />
                <div className={styles.title}>{t("virtualAssistant.stepTwoForm.fifthQuestionTitle")}</div>
                <div className={styles.amenityPreference}>
                    <div className={styles.amenityPreferenceLeft}>
                        <div className={styles.amenityNumber}>{t("virtualAssistant.stepTwoForm.bedroomNumber")}</div>
                        <Controller
                            as={CheckBoxInput}
                            multiple
                            control={form.control}
                            name={"bedrooms"}
                            rules={{
                                required: t<string>("errors.forms.required"),
                            }}
                            items={[
                                {
                                    value: 1,
                                    label: t("virtualAssistant.stepTwoForm.oneBedroom"),
                                },
                                {
                                    value: 2,
                                    label: t("virtualAssistant.stepTwoForm.twoBedrooms"),
                                },
                                {
                                    value: 3,
                                    label: t("virtualAssistant.stepTwoForm.threeBedrooms"),
                                },
                                {
                                    value: 4,
                                    label: t("virtualAssistant.stepTwoForm.fourBedroomsOrMore"),
                                },
                            ]}
                            containerClassName={clsx("flex_column gap_20", styles.checkboxLabel)}
                        />
                    </div>
                    <div className={styles.amenityPreferenceRight}>
                        <div className={styles.amenityNumber}>{t("virtualAssistant.stepTwoForm.toiletNumber")}</div>
                        <Controller
                            as={CheckBoxInput}
                            multiple
                            control={form.control}
                            name={"bathrooms"}
                            rules={{
                                required: t<string>("errors.forms.required"),
                            }}
                            items={[
                                {
                                    value: 1,
                                    label: t("virtualAssistant.stepTwoForm.oneToilet"),
                                },
                                {
                                    value: 2,
                                    label: t("virtualAssistant.stepTwoForm.twoToilets"),
                                },
                                {
                                    value: 3,
                                    label: t("virtualAssistant.stepTwoForm.threeToiletsOrMore"),
                                },
                            ]}
                            containerClassName={clsx("flex_column gap_20", styles.checkboxLabel)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
