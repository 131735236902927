import React, { useEffect } from "react";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { FeaturedProperties } from "home/sections/FeaturedProperties";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";

type Props = {
    purpose: PROPERTY_PURPOSE | "BOTH";
};

function FeaturedPropertiesSections({ purpose }: Props) {
    useEffect(() => {
        propertiesStore.fetchFeaturedProperties(undefined, purpose);
    }, []);
    return (
        <>
            {propertiesStore.featuredPropertiesState?.[purpose] && (
                <LoadableFromLoading
                    loading={propertiesStore.featuredPropertiesState[purpose]}
                    noSpinner
                    noText
                    renderer={(_status, error) => {
                        if (
                            error ||
                            !propertiesStore.featuredProperties ||
                            !propertiesStore.featuredProperties[purpose].length
                        ) {
                            return null;
                        }
                        return <FeaturedProperties properties={propertiesStore.featuredProperties[purpose]} />;
                    }}
                />
            )}
        </>
    );
}

export default FeaturedPropertiesSections;
