import React from "react";
import { Meta } from "_common/_utils/Meta";
import { useTranslation } from "react-i18next";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import i18next from "i18next";
import { URLS } from "_configs/URLS";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { getI18nExpByLang } from "_common/_utils/pageUtils";

type Props = {
    purpose?: PROPERTY_PURPOSE;
};

export function PropertiesMeta(props: Props) {
    const { t } = useTranslation();
    const { isCityPage, isProvincePage, propertyTypeLocalized } = useSearchPageParams();
    const propertiesStore = usePropertiesStore();
    const title = isProvincePage
        ? t("propertiesPage.meta.title", {
              region: propertiesStore.addressParams.region,
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          })
        : undefined;
    const description = isProvincePage
        ? t("propertiesPage.meta.description", {
              region: propertiesStore.addressParams.region,
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          }) +
          t(`propertiesPage.meta.purpose.${props.purpose}`, {
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          })
        : undefined;

    let propertyKey = "";

    const types = getI18nExpByLang(i18next.language, "property.types");
    for (const key of Object.keys(types)) {
        const tmpLang = i18next.language === "zh" ? "en" : i18next.language;
        if (
            getI18nExpByLang(tmpLang, "property.types." + key)
                .replace(/ /g, "_")
                .toLowerCase() === propertyTypeLocalized
        ) {
            propertyKey = (key as unknown) as string;
        }
    }

    const alternateUrls = [];

    for (const lang of Object.keys(sharedConfig.languages)) {
        let route = "";
        if (isCityPage) {
            route =
                sharedConfig.appUrl +
                "/" +
                lang +
                "/" +
                getI18nExpByLang(lang, props.purpose === PROPERTY_PURPOSE.BUY ? "routes.buy" : "routes.rent") +
                "/" +
                reformatStringForUrls(propertiesStore.addressParams.region) +
                "/" +
                reformatStringForUrls(propertiesStore.addressParams.city) +
                (propertiesStore.addressParams?.neighbourhood
                    ? "/" + reformatStringForUrls(propertiesStore.addressParams?.neighbourhood)
                    : "") +
                (propertyKey ? "/" + getI18nExpByLang(lang, "property.typesUrl." + propertyKey).toLowerCase() : "");
        } else if (isProvincePage) {
            route =
                sharedConfig.appUrl +
                "/" +
                lang +
                "/" +
                getI18nExpByLang(lang, props.purpose === PROPERTY_PURPOSE.BUY ? "routes.buy" : "routes.rent") +
                "/" +
                reformatStringForUrls(propertiesStore.addressParams.region) +
                "/" +
                (propertyKey ? "/" + getI18nExpByLang(lang, "property.typesUrl." + propertyKey).toLowerCase() : "");
        } else {
            route =
                sharedConfig.appUrl +
                "/" +
                lang +
                "/" +
                getI18nExpByLang(lang, "routes.buy") +
                "/" +
                (propertyTypeLocalized ? getI18nExpByLang(lang, "property.typesUrl." + propertyKey).toLowerCase() : "");
        }

        alternateUrls.push({
            url: route,
            lang: lang as TLang,
        });
    }

    const url = isCityPage
        ? URLS[props.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
              i18next.language,
              reformatStringForUrls(propertiesStore.addressParams.region),
              reformatStringForUrls(propertiesStore.addressParams.city),
              propertiesStore.addressParams?.neighbourhood
                  ? reformatStringForUrls(propertiesStore.addressParams?.neighbourhood)
                  : propertyTypeLocalized,
              propertiesStore.addressParams?.neighbourhood ? propertyTypeLocalized : undefined,
          ).toLowerCase()
        : isProvincePage
        ? URLS[props.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
              i18next.language,
              reformatStringForUrls(propertiesStore.addressParams.region),
              propertyTypeLocalized,
          ).toLowerCase()
        : URLS[props.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
              i18next.language,
              propertyTypeLocalized,
          ).toLowerCase();

    return <Meta title={title} description={description} url={url} alternateUrls={alternateUrls} />;
}
