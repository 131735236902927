import React from "react";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { ListStore } from "_common/list/ListStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { PROPERTY_FILTERS } from "properties/searchBar/filters/_utils/filtersUtils";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PropertyCard } from "properties/PropertyCard";
import { useTranslation } from "react-i18next";
import { DEFAULT_LINE, DEFAULT_PAGE_SIZE } from "_common/resources/BaseResourceStore";
import { PropertiesListLoader } from "properties/listing/PropertiesListLoader";
import { Grid } from "@material-ui/core";
import { GridPropertyCard } from "properties/GridPropertyCard";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export function PropertiesNearList(props: Props) {
    const propertiesStore = usePropertiesStore();
    const { t } = useTranslation();
    const filters = props.listStore.filters.find((filter) => filter.id === PROPERTY_FILTERS.TYPE)?.value ?? [];
    if (geoZonesStore.geoZone?._id) {
        propertiesStore.fetchNearList(
            filters,
            geoZonesStore.geoZone._id,
            (DEFAULT_LINE - Math.ceil((props.listStore.count ?? 0) / (DEFAULT_PAGE_SIZE / DEFAULT_LINE))) *
                (DEFAULT_PAGE_SIZE / DEFAULT_LINE),
        );
    }

    return (
        <LoadableFromLoading
            loading={propertiesStore.nearPropertiesState}
            fallback={<PropertiesListLoader />}
            renderer={(_status, error) => {
                if (error) return null;
                return (
                    <div className={"mt_20"}>
                        <h3>{t("propertiesPage.similarResults")}</h3>
                        {propertiesStore.nearProperties.length === 0 && (
                            <span>
                                {propertiesStore.nearProperties.length} {t("words.results")} &nbsp;
                            </span>
                        )}
                        <Grid container spacing={4}>
                            {propertiesStore.nearProperties.map((property) => {
                                if (!property) return null;
                                return (
                                    <GridPropertyCard key={property._id}>
                                        <PropertyCard
                                            property={property}
                                            onMouseOver={() => {
                                                propertiesStore.setMapPropertiesSelected(property);
                                            }}
                                            onMouseLeave={() => {
                                                propertiesStore.setMapPropertiesSelected.cancel();
                                            }}
                                        />
                                    </GridPropertyCard>
                                );
                            })}
                        </Grid>
                    </div>
                );
            }}
        />
    );
}
