import React from "react";
import styles from "./_css/popularLinks.module.css";
import { useTranslation } from "react-i18next";
import { refreshPage } from "_common/_utils/pageUtils";
import { Collapsible } from "_common/ui/components/Collapsible";
import { Grid } from "@material-ui/core";
import clsx from "clsx";

type Props = {
    type: "search" | "location";
};

function PopularLinksSection({ type, purpose }: Props & { purpose: "sale" | "rent" }) {
    const { t } = useTranslation();
    const key = type === "search" ? "popularSearches" : "popularLocations";

    return (
        <Grid container>
            {Array.from(Array(18).keys()).map((x) => {
                return (
                    <Grid key={x} item xs={6} md={4}>
                        <a
                            href={t(`footer.${key}.${purpose}.link${x + 1}`)}
                            key={`${x}${purpose}`}
                            title={t(`footer.${key}.${purpose}.label${x + 1}`)}
                            className={"fontWeight_semi text_xsmall"}
                            onClick={(e) => {
                                e.preventDefault();
                                refreshPage(t(`footer.${key}.${purpose}.link${x + 1}`));
                            }}
                        >
                            {t(`footer.${key}.${purpose}.label${x + 1}`)}
                        </a>
                    </Grid>
                );
            })}
        </Grid>
    );
}
export function PopularLinks({ type }: Props) {
    const { t } = useTranslation();
    const key = type === "search" ? "popularSearches" : "popularLocations";
    return (
        <section>
            <h2 className={clsx(styles.title, "fontWeight_normal")}> {t(`footer.${key}.title`)}</h2>
            <Collapsible heightOfCollapsable={110} moreText={t("advancedResearches.seeMore")}>
                {type === "location" ? (
                    <>
                        <h3 className={clsx(styles.subTitle, "fontWeight_normal text_big")}>{t(`footer.forSale`)}</h3>
                        <PopularLinksSection type={type} purpose={"sale"} />
                        <h3 className={"fontWeight_normal text_big mt_20"}>{t(`footer.forRent`)}</h3>
                        <PopularLinksSection type={type} purpose={"rent"} />
                    </>
                ) : (
                    <PopularLinksSection type={type} purpose={"sale"} />
                )}
            </Collapsible>
        </section>
    );
}
