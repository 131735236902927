import React from "react";
import styles from "./_css/propertyOrUnitCtaWithExternalSource.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";

type Props = {
    link: string;
};

export function PropertyOrUnitCtaFromExternalSource({ link }: Props) {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={clsx(styles.title, "mb_15")}>{t("propertyPage.cta.external.info")}</div>
            <p className={"mb_15 p_0 text_small"}>{t("propertyPage.cta.external.detail")}</p>
            <div className={clsx("flex flex_row_center gap_10", styles.action)}>
                <ExternalOrNavLink nofollow url={link}>
                    <UiButton variant={"contained"} color={"primary"}>
                        {t("propertyPage.cta.external.button")}
                    </UiButton>
                </ExternalOrNavLink>
            </div>
        </div>
    );
}
