import { Button, ButtonProps } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import styles from "./_css/uiButton.module.css";

type Props = ButtonProps & {
    isDeleteButton?: boolean;
    isSeeMoreButton?: boolean;
};

export const UiButton = React.forwardRef<HTMLDivElement, Props>(
    ({ isDeleteButton, isSeeMoreButton, variant, ...props }: Props, ref) => {
        return (
            <Button
                ref={ref}
                {...props}
                variant={variant}
                className={clsx(
                    {
                        [styles.seeMoreButton]: isSeeMoreButton,
                        [styles.containerForDelete]: isDeleteButton,
                        [styles.container]: variant === "contained",
                    },
                    props.className,
                )}
                classes={{
                    root: styles.root,
                    label: styles.label,
                    sizeSmall: styles.sizeSmall,
                    contained: styles.contained,
                    containedPrimary: styles.containedPrimary,
                    outlinedPrimary: styles.outlinedPrimary,
                }}
            />
        );
    },
);
