import React from "react";
import clsx from "clsx";
import { VideoComponent } from "components/base/video/VideoComponent";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Tour3D } from "components/base/tour3D/Tour3D";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import styles from "./_css/propertyGallerySecondary.module.css";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { useUnitStore } from "units/UnitContext";

type Props = {
    resource: MAIN_RESOURCE;
};
export function PropertyOrUnitGallerySecondary({ resource }: Props) {
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const store = resource === MAIN_RESOURCE.PROPERTIES ? propertyStore : unitStore;
    const item = resource === MAIN_RESOURCE.PROPERTIES ? propertyStore.property : unitStore.unit;

    const hasVideo1 = !!item.video;
    const hasVideo2 = !!item.video2;
    const hasPhoto1 = item.photos?.[1];
    const hasPhoto2 = item.photos?.[2];
    const has3DVisit = item.visit3D;

    return (
        <div className={clsx("flex_column justifyContent_spaceBetween hiddenMobile", styles.container)}>
            {hasVideo1 ? (
                <VideoComponent className={styles.video} url={item.video} />
            ) : (
                <>
                    {hasPhoto1 && (
                        <LazyLoadImage
                            onClick={() => store.openGallery(0)}
                            className={styles.secondaryPhoto}
                            src={item.photos[1].url}
                            alt={`${item.localized.title}-1`}
                            title={`${item.localized.title} photo 1`}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "https://via.placeholder.com/250?text=Vistoo.com";
                            }}
                        />
                    )}
                </>
            )}
            {hasVideo2 ? (
                <VideoComponent className={styles.video} url={item.video2} />
            ) : (
                <>
                    {hasPhoto2 && !has3DVisit && (
                        <LazyLoadImage
                            onClick={() => store.openGallery(0)}
                            className={styles.secondaryPhoto}
                            src={item.photos[2].url}
                            alt={`${item.localized.title}-1`}
                            title={`${item.localized.title} photo 1`}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "https://via.placeholder.com/250?text=Vistoo.com";
                            }}
                        />
                    )}
                </>
            )}
            {has3DVisit && !hasVideo2 && <Tour3D className={styles.video} embedSrc={propertyStore.property.visit3D} />}
        </div>
    );
}
