import React from "react";
import clsx from "clsx";
import { usePage, useWindowSize } from "_common/_utils/hookUtils";
import styles from "./_css/subMenu.module.css";
import { Grid } from "@material-ui/core";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { refreshPage } from "_common/_utils/pageUtils";
import { TMenuColumn } from "main/header/_menu/MenuContext";

function SubMenuContentColumn({ column }: { column: TMenuColumn }) {
    return (
        <Grid container className={styles.columnContainer}>
            {column.title ? (
                <h3 className={styles.columnTitle}>{column.title}</h3>
            ) : (
                <div className={styles.columnTitlePlaceholder} />
            )}
            {column.items.map((subItem, index) => (
                <Grid item key={index} className={styles.columnRow} xs={12}>
                    <ExternalOrNavLink
                        isBuyingOrIsRentingUrl={true}
                        url={subItem.to}
                        onClick={() => refreshPage(subItem.to)}
                        className={styles.columnRowLabel}
                    >
                        {subItem.label}
                    </ExternalOrNavLink>
                </Grid>
            ))}
        </Grid>
    );
}

function SubMenuContent({ columns, startingFromRight }: { columns: TMenuColumn[]; startingFromRight?: boolean }) {
    const { width } = useWindowSize();
    if (!__BROWSER__) {
        return (
            <Grid container spacing={3}>
                {columns.map((column, index) => (
                    <Grid item md={6} lg={3} key={index}>
                        <SubMenuContentColumn column={column} />
                    </Grid>
                ))}
            </Grid>
        );
    }
    const columnsSize = width < 1280 ? 2 : 4;
    const offset = columnsSize - columns.length;
    const columnsToFill = new Array(offset > 0 ? offset : 0).fill(null);
    return (
        <Grid container spacing={3}>
            {startingFromRight && columnsToFill.map((_, index) => <Grid key={index + "-empty"} item md={6} lg={3} />)}
            {columns.map((column, index) => (
                <Grid item md={6} lg={3} key={index}>
                    <SubMenuContentColumn column={column} />
                </Grid>
            ))}
        </Grid>
    );
}

export function SubMenu({
    columns,
    hidden,
    startingFromRight,
}: {
    hidden?: boolean;
    columns: TMenuColumn[];
    startingFromRight?: boolean;
}) {
    const { isRentOrBuyPage } = usePage();

    return (
        <div
            className={clsx(styles.container, {
                ["hidden"]: hidden,
            })}
        >
            <div
                className={clsx(styles.bgContainer, {
                    [styles.bgContainerActive]: !hidden,
                })}
            >
                <div className={clsx({ ["makeItAContainer"]: !isRentOrBuyPage })}>
                    <div className={"mh_20"}>
                        <SubMenuContent startingFromRight={startingFromRight} columns={columns} />
                    </div>
                </div>
            </div>
        </div>
    );
}
