import React from "react";
import { Redirect, useLocation } from "react-router";
import { pagesStore } from "pages/_stores/pagesStore";
import { IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { PageRenderer } from "pages/PageRenderer";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PageNotFound } from "pages/PageNotFound";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { HomePage } from "home/HomePage";
import { useHomePage } from "_common/_utils/hookUtils";
import { getPageAliasUrlForBlogPage } from "_common/_utils/pageUtils";
import { URLS } from "_configs/URLS";
import { PARAMS_PAGE_PREFIX } from "_common/_utils/searchUtils";
import { BlogsPage } from "blog/BlogsPage";

type Props = {
    isBlog?: boolean;
};

export function PageRouter({ isBlog }: Props) {
    const location = useLocation();
    const pathname = isBlog ? getPageAliasUrlForBlogPage(location.pathname) : location.pathname;
    const syncPage = pagesStore.getByUrlSync(pathname, i18nextInstance.language as TLang);
    const page = syncPage ?? pagesStore.getByUrl(pathname, i18nextInstance.language as TLang);
    const { loading } = useLoadingFromPromise("then" in page ? (page as Promise<IPageMdl>) : undefined);
    const isHomePage = useHomePage();
    if (location.pathname.split("/").length > 3 && location.pathname.split("/")?.[3].startsWith(PARAMS_PAGE_PREFIX)) {
        return <BlogsPage />;
    }
    if (syncPage?.type === PAGE_TYPE.ARTICLE && !isBlog) {
        return (
            <Redirect
                to={{
                    pathname: URLS.blog(
                        i18nextInstance.language,
                        location.pathname.substr(
                            sharedConfig.languages[i18nextInstance.language as TLang].basePath.length + 1,
                        ),
                    ),
                }}
            />
        );
    }
    if (!__BROWSER__ && !syncPage && !isHomePage) {
        return <PageNotFound />;
    }
    if (isHomePage) {
        return <HomePage />;
    }

    if ("then" in page) {
        if (!loading) return null;
        return (
            <LoadableFromLoading
                loading={loading}
                renderer={(_status, error, fetchedPage) => {
                    if (error) {
                        return <ErrorBlock error={error} />;
                    }
                    if (!fetchedPage) {
                        return <PageNotFound />;
                    }
                    return <PageRenderer page={fetchedPage} />;
                }}
            />
        );
    }

    return <PageRenderer page={page} />;
}
