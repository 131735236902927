import React, { useEffect } from "react";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import clsx from "clsx";
import styles from "properties/searchBar/featured/_css/featuredPropertiesSrps.module.css";
import { MAP_OR_LIST_SELECTED } from "properties/listing/PropertiesView";
import { usePropertiesPageContext } from "properties/PropertiesPageContext";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { observer } from "mobx-react";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { FeaturedPropertiesSrps } from "properties/searchBar/featured/FeaturedPropertiesSrps";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { propertiesStore } from "properties/_stores/propertiesStore";

type Props = {
    purpose: PROPERTY_PURPOSE | "BOTH";
};

function FeaturedPropertiesSrpsSection({ purpose }: Props) {
    const { mapOrListSelected } = usePropertiesPageContext();
    const { propertyTypeLocalized } = useSearchPageParams();
    const promise = propertiesStore.fetchFeaturedPropertiesSrps(
        undefined,
        purpose,
        geoZonesStore.geoZone?._id,
        propertyTypeLocalized,
    );
    const { loading, setPromise } = useLoadingFromPromise(promise);
    const geoZone = geoZonesStore.geoZone?._id;

    useEffect(() => {
        setPromise(promise);
    }, [geoZone]);

    if (!loading) return null;

    return (
        <LoadableFromLoading
            loading={loading}
            noSpinner
            noText
            renderer={(_status, error, properties) => {
                if (error || !properties || !properties.length) {
                    return null;
                }
                return (
                    <div
                        id="featuredPropertiesSrps"
                        className={clsx(styles.container, "position_relative mv_10 mh_20 cursor_pointer hiddenMobile", {
                            ["hidden"]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                        })}
                    >
                        <FeaturedPropertiesSrps properties={properties} />
                    </div>
                );
            }}
        />
    );
}

export default observer(FeaturedPropertiesSrpsSection);
