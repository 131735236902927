import React from "react";
import styles from "../_css/propertiesListLoader.module.css";
import { Grid } from "@material-ui/core";

export function PropertiesListLoader() {
    return (
        <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                    <div className={styles.card}>
                        <div className={styles.img} />
                        <div className={styles.content}>
                            <h2 />
                            <p />
                        </div>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
}
