import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import MultiSelect from "_common/ui/forms/MultiSelect";
import { GEO_ZONE_TYPE, TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import React from "react";

export const GeozonesMultiSelect = observer(
    ({ onChange, value }: { onChange: (value: string[]) => void; value: string[] }) => {
        const { t } = useTranslation();
        const { loading } = useLoadingFromPromise(geoZonesStore.fetchAllGeoZones());

        if (!loading) return null;

        return (
            <LoadableFromLoading
                loading={loading}
                renderer={(_status, error, data) => {
                    if (error || !data) {
                        return <ErrorBlock error={error} />;
                    }

                    const geozones = data.data.items;

                    return (
                        <MultiSelect<TGeoZoneMdl>
                            chipsLabel={t("virtualAssistant.stepOneForm.provinceCityNeighborhood")}
                            options={geozones
                                .filter((gz) => gz.type !== GEO_ZONE_TYPE.PROVINCE && !!gz.address.city)
                                .map((gz) => {
                                    if (gz.type === GEO_ZONE_TYPE.NEIGHBORHOOD) {
                                        return {
                                            name: gz.address.neighbourhood,
                                            _id: gz._id,
                                        };
                                    }
                                    return {
                                        name: gz.address.city,
                                        _id: gz._id,
                                    };
                                })
                                .sort((a, b) => a.name.localeCompare(b.name))}
                            value={value || []}
                            onChange={onChange}
                            showSelectionCounter
                            maxSelections={3}
                            placeholder={t("virtualAssistant.stepOneForm.selectPlaceholder")}
                        />
                    );
                }}
            />
        );
    },
);
