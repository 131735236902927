import { BaseResourceStore, TInitialState } from "_common/resources/BaseResourceStore";
import { GEO_ZONE_TYPE, TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { action, computed, extendObservable, observable, toJS } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { propertiesStore } from "properties/_stores/propertiesStore";
import {
    getResourceInitialStateValue,
    putItemsInInitialState,
    putObjectInInitialState,
    putPromiseResultInInitialState,
} from "_common/_utils/initialStateUtils";
import { removeAccentFromString } from "_common/_utils/alphaNumUtils";
import _ from "lodash";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import slugify from "slugify";
import { DEFAULT_LOCATION } from "_common/_utils/searchUtils";
import { WithRequiredProperty } from "_common/types/GenericTypes";
import i18next from "i18next";

export class GeoZonesStore extends BaseResourceStore<WithRequiredProperty<TGeoZoneMdl, "_id">> {
    @observable geoZone: TGeoZoneMdl | undefined;
    @observable geoZoneState: LoadingStateMdl<
        TGeoZoneMdl | Omit<TGeoZoneMdl, "localized"> | undefined
    > = new LoadingStateMdl<TGeoZoneMdl | Omit<TGeoZoneMdl, "localized"> | undefined>("IDLE");

    @observable search = "";
    @observable opened = false;
    @observable selectedIndex = -1;
    @observable geoZones: TGeoZoneMdl[] = [];
    @observable provinces: TGeoZoneMdl[] = [];
    @observable children: { [key: string]: TGeoZoneMdl[] } = {};
    @observable unlinkGeoZone: TGeoZoneMdl | undefined = undefined;
    @observable geoZonesState: LoadingStateMdl<TGeoZoneMdl[]> = new LoadingStateMdl<TGeoZoneMdl[]>();
    @observable unlinkGeoZonesState: LoadingStateMdl<TGeoZoneMdl> = new LoadingStateMdl<TGeoZoneMdl>();

    constructor() {
        super("geoZones");
        this.onInit();
    }

    @computed get address() {
        return {
            city: this.geoZone?.address?.city ?? undefined,
            province: this.geoZone?.address?.province ?? undefined,
            neighbourhood: this.geoZone?.address?.neighbourhood ?? undefined,
        };
    }

    @computed get results() {
        return {
            geoZones: this.geoZones.sort((a, b) => a.name.length - b.name.length),
        };
    }

    @computed get selectedResult() {
        return this.results.geoZones[this.selectedIndex];
    }

    @computed get isSearching() {
        return this.opened && this.search.length > 1;
    }

    @action open(opened: boolean) {
        this.opened = opened;
    }

    @action pressArrowDown() {
        if (this.results.geoZones) {
            if (this.selectedIndex === this.results.geoZones.length - 1) {
                this.selectedIndex = 0;
            } else {
                this.selectedIndex += 1;
            }
        }
    }

    @action pressArrowUp() {
        if (this.results.geoZones) {
            if (this.selectedIndex <= 0) {
                this.selectedIndex = this.results.geoZones.length - 1;
            } else {
                this.selectedIndex -= 1;
            }
        }
    }

    @action setSelectedIndex(index: number) {
        this.selectedIndex = index;
    }

    @action setSearch = _.debounce((s: string) => {
        this.open(s.length > 0);
        this.selectedIndex = -1;
        this.search = s;
        this.fetchGeozones(slugify(s), 5);
        propertiesStore.fetchPropertiesForSearch(removeAccentFromString(s), 5);
    }, 400);

    @action resetGeoZone() {
        this.geoZone = undefined;
        this.geoZoneState.setStatus("IDLE");
    }

    @action setGeoZone(geoZone: TGeoZoneMdl) {
        this.geoZone = geoZone;
    }

    @action
    async createGeoZone(
        results: {
            geometry: { location: { lat: () => number; lng: () => number } };
            address_components: {
                types: string;
                long_name: string;
                short_name: string;
            }[];
            formatted_address: string;
            types: string[];
        },
        regionLocation?: { osmId: number; coordinates: number[][] | number[][][] } | undefined,
    ) {
        this.resetGeoZone();
        this.geoZoneState.startLoading();
        const geoZone = this.getGeoZoneFromResult(results, regionLocation);
        this.geoZone = await this.create(geoZone);
        if (this.geoZone) this.geoZoneState.setSuccess(this.geoZone);
        else this.geoZoneState.setStatus("IDLE");
    }

    private getGeoZoneFromResult(
        results: {
            geometry: { location: { lat: () => number; lng: () => number } };
            address_components: {
                types: string;
                long_name: string;
                short_name: string;
            }[];
            formatted_address: string;
            types: string[];
        },
        regionLocation?: { osmId: number; coordinates: number[][] | number[][][] } | undefined,
    ): Omit<TGeoZoneMdl, "localized" | "_id"> {
        const addressComponents = results.address_components;
        const isNeighborhood = results.types.includes("sublocality") || results.types.includes("neighborhood");
        const locality = addressComponents.find((addressComponent) => addressComponent.types.includes("locality"));
        const region = addressComponents.find((addressComponent) =>
            addressComponent.types.includes("administrative_area_level_1"),
        );
        const neighbourhood = addressComponents.find((addressComponent) =>
            addressComponent.types.includes("neighborhood"),
        );
        const sublocality = addressComponents.find((addressComponent) =>
            addressComponent.types.includes("sublocality"),
        );
        const zoneName = results.formatted_address.split(",")[0];
        const geoZone = {
            name: results.formatted_address,
            location: {
                type: "Point",
                coordinates: [results.geometry.location.lng(), results.geometry.location.lat()],
            },
            osmId: regionLocation ? regionLocation?.osmId.toString() : "",
            address: {
                city: removeAccentFromString(locality ? locality?.short_name ?? locality?.long_name : zoneName ?? ""),
                province: region?.short_name ?? region?.long_name ?? "",
                neighbourhood: neighbourhood?.short_name ?? sublocality?.short_name ?? "",
            },
            type: isNeighborhood ? GEO_ZONE_TYPE.NEIGHBORHOOD : GEO_ZONE_TYPE.CITY,
            slug: "",
            zoneLocations: [DEFAULT_LOCATION],
        };
        if (regionLocation) {
            const zoneLocations = this.getZoneLocation(regionLocation);
            if (zoneLocations) {
                geoZone["zoneLocations"] = zoneLocations;
            }
        }
        return geoZone;
    }

    private getZoneLocation(regionLocation: { osmId: number; coordinates: any[] } | undefined) {
        if (
            regionLocation?.coordinates &&
            typeof regionLocation.coordinates[0] !== "number" &&
            regionLocation.coordinates.length
        ) {
            let locationLvl: number[][] = regionLocation.coordinates[0];
            const LIMIT = 4;
            let counter = 0;
            while (typeof locationLvl[0][0] !== "number" && counter <= LIMIT) {
                counter++;
                locationLvl = JSON.parse(JSON.stringify(locationLvl[0]));
            }
            return locationLvl.map((location) => ({
                lng: location[0],
                lat: location[1],
            }));
        } else {
            return undefined;
        }
    }

    fetchGeozones(value?: string, limit = 10, regionOnly = false) {
        if (!this.geoZonesState.isLoading) {
            this.geoZonesState.startLoading();
            const filters: TFilter[] = [];
            if (value) filters.push({ id: "slug", type: TFilterType.STRING, value });
            if (regionOnly) {
                filters.push({
                    id: "isRegion",
                    type: TFilterType.BOOLEAN,
                    value: true,
                });
            }

            const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
            const url = `${this.apiPath}/listing?limit=${limit}${filtersParam}`;
            return fetchUtils.get<{ items: TGeoZoneMdl[] }>(url).then(
                action(({ data: { items } }) => {
                    this.geoZones = items;
                    this.geoZonesState.setSuccess(items);
                }),
            );
        }
    }

    async fetchAllGeoZones(limit = Number.MAX_SAFE_INTEGER) {
        const url = `${this.apiPath}/listing?limit=${limit}`;
        return fetchUtils.get<{ items: TGeoZoneMdl[] }>(url);
    }

    fetchProvinces() {
        if (this.provinces.length > 0) return Promise.resolve(this.provinces);
        const filters: TFilter[] = [{ id: "type", type: TFilterType.STRING, value: GEO_ZONE_TYPE.PROVINCE }];
        return putPromiseResultInInitialState(
            "provinces",
            fetchUtils
                .get<{ items: TGeoZoneMdl[] }>(`${this.apiPath}/listing?limit=100&filters=${JSON.stringify(filters)}`)
                .then(
                    action(({ data: { items } }) => {
                        putItemsInInitialState<TGeoZoneMdl>("provinces", items);
                        this.provinces = items;
                        return items;
                    }),
                ),
        );
    }

    fetchChildrenGeozone(parentId: string) {
        if (toJS(this.children?.[parentId])) {
            return Promise.resolve(this.children[parentId]);
        }
        return putPromiseResultInInitialState(
            "children",
            fetchUtils
                .get<{ items: TGeoZoneMdl[] }>(`${this.apiPath}/children/${parentId}?lang=${i18next.language}`)
                .then(
                    action(({ data: { items } }) => {
                        this.children = { ...this.children, [parentId]: items };
                        putObjectInInitialState("children", { [parentId]: items });
                        extendObservable(this.children, { [parentId]: items });
                        return this.children;
                    }),
                ),
        );
    }

    fetchRegionOfProperty(propertyId: string) {
        return fetchUtils.get<TGeoZoneMdl>(`${this.apiPath}/regionOfProperty/${propertyId}`);
    }

    listing(
        offset = 0,
        limit?: number,
        _listId?: string,
        sort?: { [key: string]: number },
        filters?: TFilter[],
        _isShuffle = false,
    ) {
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        let filtersParam = "";
        if (filters) filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/listing?offset=${offset}&limit=${limit}${sortParam}${filtersParam}`;
        const promise = fetchUtils
            .get<{ count: number; items: TGeoZoneMdl[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map((item) => {
                    const reformattedItem = this.reformatItem(item);
                    this.putItemInCache(reformattedItem);
                    return reformattedItem;
                }),
            }));
        return promise;
    }

    protected onInit(_fromRootCtor?: boolean) {
        this.geoZoneState.startLoading();

        const initialState = getResourceInitialStateValue("geoZone") as
            | TInitialState<WithRequiredProperty<TGeoZoneMdl, "_id">>
            | undefined;
        if (initialState) {
            this.geoZone = initialState.items[0];
            this.geoZoneState.setSuccess(this.geoZone);
        } else this.geoZoneState.setStatus("IDLE");

        const provincesInitialState = getResourceInitialStateValue("provinces") as
            | TInitialState<WithRequiredProperty<TGeoZoneMdl, "_id">>
            | undefined;

        if (provincesInitialState) {
            this.provinces = provincesInitialState.items;
        }

        const childrenInitialState = getResourceInitialStateValue("children");

        if (childrenInitialState) {
            this.children = childrenInitialState;
        }
    }

    protected onReset() {
        this.geoZoneState = new LoadingStateMdl();
        this.geoZone = undefined;
        this.geoZonesState = new LoadingStateMdl();
        this.geoZones = [];
        this.provinces = [];
        this.children = {};
        super.onReset();
    }
}

export const geoZonesStore = new GeoZonesStore();
