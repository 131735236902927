import React from "react";
import { observer } from "mobx-react";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import clsx from "clsx";
import { PropertyCard } from "properties/PropertyCard";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import styles from "../_css/propertiesViewList.module.css";
import { DEFAULT_PAGE_SIZE } from "_common/resources/BaseResourceStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PropertiesListLoader } from "properties/listing/PropertiesListLoader";
import { PropertiesPaginator } from "properties/listing/PropertiesPaginator";
import { getUrlWithPagination } from "_common/_utils/pageUtils";
import { useHistory } from "react-router";
import { PlacementSrps } from "placements/PlacementSrps";
import { Grid, NoSsr } from "@material-ui/core";
import { PropertiesNearList } from "properties/listing/PropertiesNearList";
import { usePropertiesPageContext } from "properties/PropertiesPageContext";
import { MAP_OR_LIST_SELECTED } from "properties/listing/PropertiesView";
import { AdvancedResearchSrPs } from "advancedResearches/AdvancedResearchSRPs";
import { Footer } from "main/footer/Footer";
import { GridPropertyCard } from "properties/GridPropertyCard";
import { PropertiesFaq } from "properties/PropertiesFaq";
import { PropertiesNoResults } from "properties/listing/PropertiesNoResults";
import { useTranslation } from "react-i18next";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export const PropertiesViewList = observer(({ listStore }: Props) => {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const history = useHistory();
    const { mapOrListSelected } = usePropertiesPageContext();

    if (
        listStore.selectedPage > 1 &&
        (!listStore.count || listStore.count < DEFAULT_PAGE_SIZE * (listStore.selectedPage - 1))
    ) {
        listStore.setSelectedPage(1);
        history.push(getUrlWithPagination(history.location.pathname, 1) + history.location.search);
    }

    const paginationIsDisplay = !!listStore.count && listStore?.count >= DEFAULT_PAGE_SIZE;

    const hasNoResults = !listStore.count || listStore.count === 0;

    const displayNearProperties = !!(
        geoZonesStore.geoZone &&
        geoZonesStore.geoZone?._id &&
        (!listStore.count || listStore.count <= 6)
    );

    const purpose = propertiesStore.purpose;

    return (
        <>
            <div className={clsx("p_20 pt_0", styles.container)} id={"searchPage"}>
                {hasNoResults && <PropertiesNoResults listStore={listStore} />}
                <Grid alignItems="center" container spacing={4}>
                    {listStore.currentLoadingState ? (
                        <LoadableFromLoading
                            loading={listStore.currentLoadingState}
                            fallback={<PropertiesListLoader />}
                            renderer={() => {
                                return (
                                    <>
                                        {listStore.paginatedItems.map((property, idx) => {
                                            if (!property) return null;
                                            return (
                                                <>
                                                    <GridPropertyCard key={property._id}>
                                                        <PropertyCard
                                                            property={property}
                                                            onMouseOver={() => {
                                                                propertiesStore.setMapPropertiesSelected(property);
                                                            }}
                                                            onMouseLeave={() => {
                                                                propertiesStore.setMapPropertiesSelected.cancel();
                                                            }}
                                                        />
                                                    </GridPropertyCard>
                                                    <NoSsr defer={true}>
                                                        <PlacementSrps listStore={listStore} srpIndex={idx} />
                                                    </NoSsr>
                                                </>
                                            );
                                        })}
                                    </>
                                );
                            }}
                        />
                    ) : (
                        <>
                            {listStore.paginatedItems.map((property, idx) => {
                                if (!property) return null;
                                return (
                                    <>
                                        <GridPropertyCard key={property._id}>
                                            <PropertyCard
                                                property={property}
                                                onMouseOver={() => {
                                                    propertiesStore.setMapPropertiesSelected(property);
                                                }}
                                                onMouseLeave={() => {
                                                    propertiesStore.setMapPropertiesSelected.cancel();
                                                }}
                                            />
                                        </GridPropertyCard>

                                        <NoSsr defer={true}>
                                            <PlacementSrps listStore={listStore} srpIndex={idx} />
                                        </NoSsr>
                                    </>
                                );
                            })}
                        </>
                    )}
                </Grid>
                {paginationIsDisplay && (
                    <div className={clsx("flex_center_center pt_40 pb_10 mb_40", styles.paginator)}>
                        <PropertiesPaginator listStore={listStore} className={styles.listStorePaginator} />
                    </div>
                )}
                {displayNearProperties && <div className={styles.separator}>{t("propertiesPage.endOfResults")}</div>}
                {displayNearProperties && <PropertiesNearList listStore={listStore} />}
                <div className={clsx(styles.advancedResearches)}>
                    <AdvancedResearchSrPs />
                </div>
                <PropertiesFaq geoZone={geoZonesStore.geoZone} purpose={purpose} />
            </div>
            {mapOrListSelected === MAP_OR_LIST_SELECTED.LIST && <Footer />}
        </>
    );
});
